import { gql } from "@apollo/client";
export const UPDATE_EMPLOYEES = gql`
 mutation UpdateUser($data: UserInput!, $where: WhereById!) {
  updateUser(data: $data, where: $where) {
    status
  }
}
`;

export const EMPLOYEE_QUEY = gql`
  query Users($where: UserWhereInput, $limit: Int, $skip: Int) {
    users(where: $where, limit: $limit, skip: $skip) {
      data {
        _id
        cvID
        profileImage
        firstName
        lastName
        gender
        dateOfBirth
        age
        phoneNumber
        workStartDate
        workEndDate
        department {
          title_lao
        }
        team {
          title_lao
        }
        province {
          title
        }
        district {
          title
        }
        village {
          title
        }
        livingIncome
        taxIncome
        basicSalary
        allowance
        fuelMoney
        positionSalary
        InsuranceExpense
        status
        role
        bankAccountName
        bankAccountNumber
        fullNameEng
        note
        position {
          _id
          title_lao
        }
      }
    }
  }
`;
