import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import useReactRouter from "use-react-router";
import {
  currency,
  getStaffLogin,
  loadingData,
  messageSuccess,
  messageWarning,
  notiflixConfirm,
} from "../../helper";
import { QUERY_PAYROLL_SUMMARY, UPDATE } from "./apollo";
import "./utils/index.css";
import { HISTORY } from "../../routes/app";
import DetailExtra from "./DetaileExtra";
import DetailDiLigent from "./DetailDiLigent";
import DetailDeduction from "./DetailDeduction";
import DetailBorrow from "./DetailBorrow";
import DetailIBonusMoney from "./DetailIBonusMoney";
import OtDetail from "./OtDetail";
import { Button } from "react-bootstrap";

export default function DetailItemMoney() {
  const { match, history } = useReactRouter();
  const _id = atob(match?.params?._id);
  const userState = getStaffLogin();
  const [reloadData, setReloadData] = useState(false);
  const [getDataDetailExtra, setGetDataDetailExtra] = useState();
  const [otDetail, setOtDetail] = useState();
  const [detailIBonus, setDetailIBonus] = useState();
  const [detailDiLigent, setDetailDiLigent] = useState();
  const [detailDeduction, setDetailDeduction] = useState();
  const [detailBorrow, setDetailBorrow] = useState();
  const [upDateConfirm] = useMutation(UPDATE);
  const [listData, setListData] = useState();
  const [fetchData, { data: dataPayrollSummary, loading }] = useLazyQuery(
    QUERY_PAYROLL_SUMMARY,
    {
      fetchPolicy: "network-only",
    }
  );
  useEffect(() => {
    fetchData({
      variables: {
        where: {
          empID: userState?._id,
          confirmStatus: "UNCONFIRMED",
        },
        orderBy: "DESC",
      },
    });
  }, [reloadData]);
  useEffect(() => {
    setListData(dataPayrollSummary?.summaryPayroll?.data);
  }, [dataPayrollSummary]);
  const _conFirm = async (_id) => {
    notiflixConfirm("ຕ້ອງການຢືນຢັນຖືກຕ້ອງແທ້ ຫຼື ບໍ່?", async () => {
      try {
        const { data: updateData } = await upDateConfirm({
          variables: {
            data: {
              confirmStatus: "CONFIRMED",
            },
            where: {
              _id: _id,
            },
          },
        });
        if (updateData) {
          messageSuccess("ຢືນຢັນເງິນເດືອນສຳເລັດ");
          setReloadData(!reloadData);
        }
      } catch (error) {
        messageWarning("ຢືນຢັນບໍ່ສຳເລັດ");
        console.log(error);
      }
    });
  };
  useEffect(() => {
    if (!_id) {
      return;
    }
    fetchData({
      variables: {
        where: {
          _id: parseInt(_id),
        },
      },
    });
  }, [_id, reloadData]);

  return (
    <>
      <div id="appCapsule">
        <div className="justify-content-md-center" />
        <div className="appHeader text-light border-0">
          <div style={{ flex: 1 }} className="text-left">
            <button
              className="btn text-white mr-2"
              onClick={() => history.push(`${HISTORY}/confirm`)}
            >
              <i className="icon-x fs-4" />
            </button>
          </div>
          ລາຍລະອຽດເງິນເດືອນ
          <div
            className="text-white pageTitle text-right text-nowrap pr-0"
            style={{ flex: 1 }}
          >
            <button
              className="btn text-white mr-0"
              onClick={() => setReloadData(!reloadData)}
            >
              {loading ? loadingData(23) : <i className="icon-cycle fs-4" />}
            </button>
          </div>
        </div>
        <div className=" me-1 p-1" style={{ marginTop: -50 }}>
          <div className="text-center">{loading && loadingData(25)}</div>
          {listData?.map((data, index) => (
            <>
              <div className="container" key={index}>
                <div className="card pt-2 text-white" style={{background: "#400a36"}} id="border">
                  <p className="ml-2 text-white">ຊື່ບັນຊີ: {data?.bankAccountName ?? ""}</p>
                  <p className="ml-2 text-white">ເລກບັນຊີ: {data?.bankAccountNumber ?? 0}</p>
                </div>
                <div className=" bill-item-list pt-2" id="border">
                  <span>1. ເດືອນ/ປີ</span>
                  <span className="text-black text-right user-select-all">
                    {data?.forMonth ? data?.forMonth : "-"} /{" "}
                    {data?.forYear ? data?.forYear : "-"}
                  </span>
                </div>
                <div className=" bill-item-list pt-2" id="border">
                  <span>2. ເງິນເດືອນພື້ນຖານ</span>
                  <span className="text-black">
                    {data?.basicSalary ? currency(data?.basicSalary) : 0}
                  </span>
                </div>
                <div className=" bill-item-list pt-2" id="border">
                  <span>3. ເງິນອາຫານ</span>
                  <span className="text-black">
                    {data?.livingSalary ? currency(data?.livingSalary) : 0}
                  </span>
                </div>
                <div className=" bill-item-list pt-2" id="border">
                  <span>4. ເງິນອຸດໜຸນຄ່າຄອງຊີບ</span>
                  <span className="text-black">
                    {data?.allowance ? currency(data?.allowance) : 0}
                  </span>
                </div>
                <div className=" bill-item-list pt-2" id="border">
                  <span>4. ເງິນຄ່ານ້ຳມັນ</span>
                  <span className="text-black">
                    {data?.fuelMoney ? currency(data?.fuelMoney) : 0}
                  </span>
                </div>
                <div className=" bill-item-list pt-2" id="border">
                  <span>5. ເງິນຕຳແຫນ່ງ</span>
                  <span className="text-black">
                    {data?.positionSalary ? currency(data?.positionSalary) : 0}
                  </span>
                </div>
                <div className=" bill-item-list pt-2" id="border">
                  <span>6. ເງິນອາກອນ</span>
                  <span className="text-black">
                    {data?.taxIncome ? currency(data?.taxIncome) : 0}
                  </span>
                </div>
                <div
                  className=" bill-item-list pt-2"
                  id="border"
                  onClick={() => setGetDataDetailExtra(data?._id)}
                >
                  <span>7. ເງິນເພີ່ມ</span>
                  <span className="text-black">
                    {data?.extraIncome ? currency(data?.extraIncome) : 0}
                    <i className="fa-solid fa-angle-right ms-1 text-secondary" />
                  </span>
                </div>
                <div
                  className=" bill-item-list pt-2"
                  id="border"
                  onClick={() => setOtDetail(data?._id)}
                >
                  <span>8. ເງິນໂອທີ </span>
                  <span className="text-black">
                    {data?.otIncome ? currency(data?.otIncome) : 0}
                    <i className="fa-solid fa-angle-right ms-1 text-secondary" />
                  </span>
                </div>
                <div
                  className=" bill-item-list pt-2"
                  id="border"
                  onClick={() => setDetailIBonus(data?._id)}
                >
                  <span>9. ເງິນໂບນັດ</span>
                  <span className="text-black">
                    {data?.bonusIncome ? currency(data?.bonusIncome) : 0}
                    <i className="fa-solid fa-angle-right ms-1 text-secondary" />
                  </span>
                </div>
                <div
                  className=" bill-item-list pt-2"
                  id="border"
                  onClick={() => setDetailDiLigent(data?._id)}
                >
                  <span>10. ເງິນຂະຫຍັນ </span>
                  <span className="text-black">
                    {data?.diligentIncome ? currency(data?.diligentIncome) : 0}
                    <i className="fa-solid fa-angle-right ms-1 text-secondary" />
                  </span>
                </div>
                <div
                  className=" bill-item-list pt-2"
                  id="border"
                  onClick={() => setDetailDeduction(data?._id)}
                >
                  <span>11. ເງິນຫັກ</span>
                  <span className="text-black">
                    {data?.deductionExpense
                      ? currency(data?.deductionExpense)
                      : 0}
                    <i className="fa-solid fa-angle-right ms-1 text-secondary" />
                  </span>
                </div>
                <div
                  className=" bill-item-list pt-2"
                  id="border"
                  onClick={() => setDetailBorrow(data?._id)}
                  s
                >
                  <span>12. ເງິນເບີກລ່ວງຫນ້າ </span>
                  <span className="text-black">
                    {data?.borrowExpense ? currency(data?.borrowExpense) : 0}
                    <i className="fa-solid fa-angle-right ms-1 text-secondary" />
                  </span>
                </div>
                <div className=" bill-item-list pt-2" id="border">
                  <span>13. ເງິນປະກັນສັງຄົມ</span>
                  <span className="text-black">
                    {data?.InsuranceExpense
                      ? currency(data?.InsuranceExpense)
                      : 0}
                  </span>
                </div>
                <div className=" bill-item-list pt-2" id="border">
                  <span>14. ຍອດເງິນສຸດທິທີ່ຈະໄດ້ຮັບ</span>
                  <span className="text-success fs-5">
                    {data?.finalIncome ? currency(data?.finalIncome) : 0}
                  </span>
                </div>
                {data?.confirmStatus === "UNCONFIRMED" ? (
                  <>
                    <Button
                      type="button"
                      className="btn btn-success btn-block mt-5"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        _conFirm(data?._id);
                      }}
                    >
                      <i className="fa-solid fa-circle-check"></i> &nbsp; ຢືນຢັນ
                    </Button>
                  </>
                ) : (
                  <>
                    <center className="text-center mt-5 text-success">
                      ທ່ານໄດ້ຢືນຢັນແລ້ວ
                    </center>
                  </>
                )}
              </div>
            </>
          ))}
        </div>
        <DetailExtra
          _id={getDataDetailExtra}
          onHide={() => setGetDataDetailExtra()}
        />
        <OtDetail _id={otDetail} onHide={() => setOtDetail()} />
        <DetailIBonusMoney
          _id={detailIBonus}
          onHide={() => setDetailIBonus()}
        />
        <DetailDiLigent
          _id={detailDiLigent}
          onHide={() => setDetailDiLigent()}
        />
        <DetailDeduction
          _id={detailDeduction}
          onHide={() => setDetailDeduction()}
        />
        <DetailBorrow _id={detailBorrow} onHide={() => setDetailBorrow()} />
      </div>
    </>
  );
}
