import { gql } from "@apollo/client";
export const QUERY_PAYROLL_SUMMARIES = gql`
  query SummaryPayrollPayment($where: SummaryPayrollWhereInput,
   $orderBy: SummaryPayrollOrderBy, $skip: Int, $limit: Int) {
  summaryPayroll(where: $where, orderBy: $orderBy, 
  skip: $skip, limit: $limit) {
    total
    data {
      _id
      empID {
        _id
        cvID
        profileImage
        gender
        firstName
        lastName
      }
      consolidatedAt
      consolidatedBy {
        _id
        firstName
        lastName
      }
      basicSalary
      allowance
      positionSalary
      livingSalary
      fuelMoney
      extraIncome
      otIncome
      bonusIncome
      diligentIncome
      deductionExpense
      borrowExpense
      taxIncome
      InsuranceExpense
      finalIncome
      approveStatus
      approvedDate
      approvedBy {
        _id
        firstName
        lastName
      }
      confirmStatus
      confirmedDate
      paidStatus
      paidDate
      paidBy {
        _id
        firstName
        lastName
      }
      ibankID {
        _id
        billNo
      }
      forMonth
      forYear
      note
      department {
        _id
        title_lao
        title_eng
      }
      position {
        _id
        title_lao
        title_eng
      }
    }
  }
}`;