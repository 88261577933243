import { RiArrowGoBackFill } from "react-icons/ri";
import useReactRouter from "use-react-router";
import { MENU } from "../../routes/app";
import { Card, Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import Schema from "./Apollo";
import { useLazyQuery, useMutation } from '@apollo/client';
import { formatDate, formatDateTime, getStaffLogin, loadingData, messageError, messageSuccess, notiflixConfirm } from "../../helper";
import SignatureApprove from "./Component/SignatureApprove";
import NoData from "../../helper/components/NoData";

export default function Approve() {
    const { history } = useReactRouter();
    const [reloadData, setReloadData] = useState(false);
    const [data, setData] = useState([]);
    const user = getStaffLogin();
    const [file, setFile] = useState("");
    const [imageURL, setImageURL] = useState(null);
    const [updateVacation] = useMutation(Schema.UPDATE_VACATION);
    const [queryVacation, { data: result, loading }] = useLazyQuery(Schema.QUERY_VACATION, {
        fetchPolicy: "cache-and-network",
    });

    useEffect(() => {
        const department = user?.department?._id;
        queryVacation({
            variables: {
                where: {
                    isApproved: "PENDING",
                    department: department,
                },
                orderBy: "DESC",
            },
        });
    }, [reloadData]);

    useEffect(() => {
        setData(result?.vacation?.data);
    }, [result]);


    const handleNotPass = (_id) => {
        notiflixConfirm("ຕ້ອງການບໍ່ອານຸມັດ ແທ້ ຫຼື ບໍ່?", async () => {
            try {
                const Update = await updateVacation({
                    variables: {
                        where: {
                            _id: _id,
                        },
                        data: {
                            isApproved: "NOT_PASS",
                        },
                    },
                });
                if (Update) {
                    messageSuccess("ການດຳເນີນງານສຳເລັດ");
                    setReloadData(!reloadData);
                } else {
                    messageError("ການດຳເນີນງານບໍ່ສຳເລັດ");
                }
            } catch (error) {
                console.log(error);
                messageError("ມີຂໍ້ມູນບາງຢ່າງຜິດພາດ");
            }
        });
    };

    return (
        <> <div id="appCapsule">
            <div className="justify-content-md-center">
                <div className="appHeader text-light border-0" >
                    <div className="col-3">
                        <a
                            onClick={() => history.push(`${MENU}`)}
                            style={{ cursor: 'pointer' }}
                        >
                            <RiArrowGoBackFill size={20} />
                        </a>
                    </div>
                    <div style={{ flex: 1 }} className="col-6 text-center">
                        ອະນຸມັດໃບລາພັກ
                    </div>
                    <div
                        className="col-3 text-white pageTitle text-right text-nowrap pr-0"
                        style={{ flex: 1 }}
                    >
                        <button
                            className="btn text-white mr-0"
                            onClick={() => setReloadData(!reloadData)}
                        >
                            {loading ? loadingData(23) : <i className="icon-cycle fs-4" />}
                        </button>
                    </div>
                </div>
                <div className="container">
                    {data?.length > 0 ? (
                        data.map((item, index) => (
                            <Card
                                className="p-1 mb-5"
                                style={{ marginTop: "-30px", boxShadow: "0 20px 40px rgba(0, 0, 0, 0.2)" }}
                                key={index}
                            >
                                <div className="text-center">
                                    <h3>ໃບສະເໝີຂໍລາພັກ</h3>
                                </div>
                                <Row>
                                    <Col className="Col-6">
                                        <div>
                                            ຊື້: {item?.createBy?.firstName} {item?.createBy?.lastName}
                                        </div>
                                        <div>
                                            ວັນທີ:{" "}
                                            {item?.startDate ? formatDate(item?.startDate) : "-"} ຫາ{" "}
                                            {item?.endDate ? formatDate(item?.endDate) : "-"}
                                        </div>
                                        <div>
                                            ວັນທີສ້າງ: {formatDateTime(item?.createDate)}
                                        </div>
                                        <div>
                                            ຈຳນວນມື້ລາພັກ: {item?.vacationId?.title}{" "}
                                            <span className="text-dark">{item?.isDay}</span> ວັນ
                                        </div>
                                        <div>ເຫດຜົນ: {item?.detail}</div>
                                        <hr />
                                        <div className="col-12 mt-1 mb-1">
                                            <Row>
                                                <Col className="col-6">
                                                    <div className="text-center text-nowrap">
                                                        <SignatureApprove
                                                            setImageURL={setImageURL}
                                                            setFile={setFile}
                                                            data={item}
                                                            setReloadData={setReloadData}
                                                            reloadData={reloadData}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className="col-6 ">
                                                    <div className="text-center text-nowrap">
                                                        <button
                                                            className="btn btn-dark"
                                                            onClick={() => handleNotPass(item?._id)}
                                                        >
                                                            ບໍ່ອານຸມັດ
                                                        </button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        ))
                    ) : (
                        <NoData loading={loading} />
                    )}
                </div>
            </div>
        </div>
        </>
    );
}