import ApolloClientProvider from "./configs/ApolloclienProvider";
import Routes from "./routes";

export default function App() {
  return (
    <>
      <ApolloClientProvider>
        <Routes/>
      </ApolloClientProvider>
    </>
  );
}
