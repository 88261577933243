import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import useReactRouter from "use-react-router";
import "./index.css";
import BottomNav from "../../layouts/BottomNav";
import { QUERY_PAYROLL_SUMMARIES } from "./apollo";
import male from "../../img/male.png";
import female from "../../img/female.png";
import {
  aws_url_employee_Image,
  currency,
  getStaffLogin,
  loadingData,
} from "../../helper";
import OtherMoney from "./OtherMoney";
import { HISTORY } from "../../routes/app";
import moment from "moment";

export default function Home() {
  const { history } = useReactRouter();
  const userState = getStaffLogin();
  const [total, setTotal] = useState(0);
  const currentMonth = moment(new Date()).format("MM");
  const [getPayrollSummary, setDataPayrollSummary] = useState([]);

  const [fetchItem, { data: setNoticeConfirm, loading: loading }] =
    useLazyQuery(QUERY_PAYROLL_SUMMARIES, {
      fetchPolicy: "cache-and-network",
    });
  const [fetchData, { data: dataPayrollSummary, loading: loadingTotal }] =
    useLazyQuery(QUERY_PAYROLL_SUMMARIES, {
      fetchPolicy: "cache-and-network",
    });

  useEffect(() => {
    fetchData({
      variables: {
        where: {
          empID: parseInt(userState?._id),
          confirmStatus: "UNCONFIRMED",
          approveStatus: "FALSE",
          paidStatus: "UNPAID",
        },
        orderBy: "DESC",
      },
    });
  }, [fetchData, currentMonth]);

  useEffect(() => {
    fetchItem({
      variables: {
        where: {
          empID: parseInt(userState?._id),
          confirmStatus: "UNCONFIRMED",
        },
        orderBy: "DESC",
      },
    });
  }, [fetchItem]);

  useEffect(() => {
    setDataPayrollSummary(dataPayrollSummary?.summaryPayroll?.data[0]);
  }, [dataPayrollSummary]);

  useEffect(() => {
    if (setNoticeConfirm) {
      setTotal(setNoticeConfirm?.summaryPayroll?.data?.length);
    }
  }, [setNoticeConfirm]);

  //ລວມຈຳນວນເງິນເພິ່ມ
  const _extraIncome = parseInt(getPayrollSummary?.extraIncome);
  const _otIncome = parseInt(getPayrollSummary?.otIncome);
  const _bonusIncome = parseInt(getPayrollSummary?.bonusIncome);
  const _diligentIncome = parseInt(getPayrollSummary?.diligentIncome);
  const _totalExtraIncome =
    _extraIncome + _otIncome + _bonusIncome + _diligentIncome;

  //ລວມຈຳນນວນເງິນຫັກ
  const _deductionExpense = parseInt(getPayrollSummary?.deductionExpense);
  const _borrowExpense = parseInt(getPayrollSummary?.borrowExpense);
  const _taxIncome = parseInt(getPayrollSummary?.taxIncome);
  const _InsuranceExpense = parseInt(getPayrollSummary?.InsuranceExpense);

  const _totalDeductionExpense =
    _deductionExpense + _borrowExpense + _taxIncome + _InsuranceExpense;

  // ເງິນລວມທັງຫມົດ
  const _positionSalary = parseInt(getPayrollSummary?.positionSalary);
  const _Total =
    Number(_positionSalary) +
    Number(getPayrollSummary?.basicSalary) +
    Number(getPayrollSummary?.allowance) +
    Number(getPayrollSummary?.fuelMoney) +
    Number(getPayrollSummary?.livingSalary);

  return (
    <>
      <div
        id="appCapsule"
        style={{
          backgroundColor: "#400a36",
          marginBottom: 0,
          paddingBottom: 0,
        }}
      >
        <div className="appHeader text-light border-0 text-right">
          <div style={{ flex: 1 }} className="text-left"></div>
          ໜ້າຫຼັກ
          <div
            className="text-white pageTitle text-center text-nowrap pr-0"
            style={{ flex: 1 }}
          >
            <a
              className="mr-3 float-right"
              onClick={(e) => history.push(`${HISTORY}/confirm`)}
            >
              <i className="icon-bell" style={{ fontSize: 20 }} />
              {loadingTotal ? (
                <span style={{ position: "absolute", right: 10, top: 10 }}>
                  {loadingData(10)}
                </span>
              ) : total > 0 ? (
                <span className="badge badge-success mr-1 p-2">
                  <small>{total ? total : 0}</small>
                </span>
              ) : null}
            </a>
          </div>
        </div>
        <div className="body-content body-content-lg ">
          <div className="container">
            <div
              className="add-card section-to-header mb-30"
              style={{ marginTop: -140, boxShadow: "inset 0 0 1px red" }}
            >
              <div className="add-card-inner">
                <div className="add-card-item add-card-info">
                  <p>ເງິນເດືອນລວມທັງຫມົດ</p>
                  {loading ? (
                    loadingData(25)
                  ) : (
                    <>
                      <h3> {_Total ? currency(_Total) : 0} </h3>
                    </>
                  )}
                </div>
                <div className="add-card-item add-baflance">
                  <a href="javascript:void(0)" className="p-1">
                    {userState?.gender === "MALE" ? (
                      <img
                        className="img-zoom-hover"
                        src={
                          userState?.profileImage
                            ? `${aws_url_employee_Image}/${userState?.profileImage}`
                            : male
                        }
                        style={{
                          height: 50,
                          width: 50,
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <>
                        <img
                          className="img-zoom-hover"
                          src={
                            userState?.profileImage
                              ? `${aws_url_employee_Image}/${userState?.profileImage}`
                              : female
                          }
                          style={{
                            height: 50,
                            width: 50,
                            cursor: "pointer",
                          }}
                        />
                      </>
                    )}
                    <br />
                    ID: {userState?.cvID ? userState?.cvID : "-"}
                  </a>
                </div>
              </div>
            </div>
            <div className="option-section mb-15">
              <div className="row gx-2">
                <div className="col-6 pb-15">
                  <div className="option-card option-card-violet">
                    <a
                      href="javascript:void(0)"
                      data-bs-toggle="modal"
                      data-bs-target="#withdraw"
                    >
                      <div className="option-card-icon">
                        {loading ? (
                          loadingData(25)
                        ) : (
                          <>
                            <h3>
                              {_totalExtraIncome
                                ? currency(_totalExtraIncome)
                                : 0}{" "}
                            </h3>
                          </>
                        )}
                      </div>
                      <p>ເງິນເພີ່ມ</p>
                    </a>
                  </div>
                </div>
                <div className="col-6 pb-15">
                  <div className="option-card option-card-blue">
                    <a href="javascript:void(0)">
                      <div className="option-card-icon">
                        {loading ? (
                          loadingData(25)
                        ) : (
                          <>
                            <h3>
                              {_totalDeductionExpense
                                ? currency(_totalDeductionExpense)
                                : 0}{" "}
                            </h3>
                          </>
                        )}
                      </div>
                      <p>ເງິນຫັກ</p>
                    </a>
                  </div>
                </div>
                <OtherMoney data={getPayrollSummary} loading={loading} />
                <div className="col-12 pb-15">
                  <div className="option-card option-card-red">
                    <a
                      href="javascript:void(0)"
                      data-bs-toggle="modal"
                      data-bs-target="#exchange"
                    >
                      <div className="option-card-icon">
                        {loading ? (
                          loadingData(25)
                        ) : (
                          <>
                            <h3>
                              {getPayrollSummary
                                ? currency(getPayrollSummary?.finalIncome)
                                : 0}{" "}
                            </h3>
                          </>
                        )}
                      </div>
                      <p>ເງິນໄດ້ຮັບສຸດທິ</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BottomNav />
      </div>
    </>
  );
}
