import React, { useEffect, useState } from "react";
import { IoReturnDownBackOutline } from "react-icons/io5";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import Schema from "../Apollo";
import { useLazyQuery } from "@apollo/client";
import { Card, Col, Row } from "react-bootstrap";
import FromVote from "./FromVote";
import BackToTop from "../../../until/BackToTop";
import Loading from "../../../img/loadings.gif";
import SelectUser from "../../../helper/components/SelectUser";
import { getStaffLogin } from "../../../helper";
import Swal from "sweetalert2";

export default function HomeVote() {
  const history = useHistory();
  const [item, setItem] = useState([]);
  const [itemBranch, setItemBranch] = useState([]);
  const [itemCenter, setItemCenter] = useState([]);
  const [typeCheck, setTypeCheck] = useState("");
  const [loading, setLoading] = useState(true);
  const [searchDepartment, setSearchDepartment] = useState();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [checkUser, setCheckUser] = useState("");
  const [searchUser, setSearchUser] = useState();
  const userLogin = getStaffLogin();

  useEffect(() => {
    if (location.pathname === "/home_vote") {
      showAlert();
    }
  }, [location.pathname]);

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const [FetchDataUser, { data: user, loading: loadingUser }] = useLazyQuery(
    Schema.USER,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const [FetchDataBranch, { data: branch, loading: loadingBranch }] =
    useLazyQuery(Schema.BRANCH, {
      fetchPolicy: "cache-and-network",
    });

  const [FetchDataCenter, { data: center, loading: loadingCenter }] =
    useLazyQuery(Schema.CENTER, {
      fetchPolicy: "cache-and-network",
    });

  useEffect(() => {
    FetchDataUser({
      variables: {
        where: {
          _id: searchUser?._id ? parseInt(searchUser?._id) : undefined,
          status: "ACTIVE",
          typePosition: typeCheck,
        },
        orderBy: "ASC",
      },
    });
    FetchDataBranch({
      variables: {
        where: {
          isDeleted: 0,
        },
        limit: 1000,
      },
    });
    FetchDataCenter({
      variables: {
        where: {},
      },
    });
  }, [typeCheck, loading, searchUser, checkUser]);

  useEffect(() => {
    if (!checkUser) {
      setItem(user?.users?.data || []);
    } else {
      const filteredUser = (user?.users?.data || []).filter((item) => {
        if (checkUser === "BRANCH_USER") {
          return !item.center || item.center.st_id === null;
        } else if (checkUser === "CENTER_USER") {
          return item.center && item.center.st_id !== null;
        }
      });
      setItem(filteredUser);
    }
    const filteredBranch = (branch?.branches?.data || []).filter(
      (item) =>
        item._id !== 2 &&
        item._id !== 14 &&
        item._id !== 21 &&
        item._id !== 46 &&
        item._id !== 45 &&
        item._id !== 44 &&
        item._id !== 42 &&
        item._id !== 40 &&
        item._id !== 39 &&
        item._id !== 38 &&
        item._id !== 15 &&
        item._id !== 12 &&
        item._id !== 13 &&
        item._id !== 16 &&
        item._id !== 41 &&
        item._id !== 18 &&
        item._id !== 19
    );
    setItemBranch(filteredBranch);
    const filteredCenters = (center?.centers?.data || []).filter(
      (item) =>
        item.st_id !== 7 &&
        item.st_id !== 56 &&
        item.st_id !== 58 &&
        item.st_id !== 62 &&
        item.st_id !== 61 &&
        item.st_id !== 67 &&
        item.st_id !== 13
    );
    setItemCenter(filteredCenters);
  }, [user, branch, center, searchDepartment, loading, checkUser, searchUser]);

  const handleBackClick = () => {
    history.goBack();
  };

  const showAlert = () => {
    Swal.fire({
      // title: '<strong><span class="text-danger">ເເຈ້ງເຕືອນ</span></strong>',
      html: `
        <div style="text-align: center;">
          <p>ກະລຸນາເລືອກຫົວຂໍ້ ເເລະ ກວດສອບກອນຢືນຢັນທຸກຄັ້ງ</p>
        </div>
      `,
      icon: "question",
      confirmButtonText: "ຍອມຮັບ",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        handleCloseModal();
      }
    });
  };

  //ສິດທີ່ໃຫຍ່ກວ່າຫຼືເທົ່າກັບຫົວໜ້າໜ່ວຍງານ
  const Hight_Agency = [8, 9, 10, 119, 68];

  //ສະເພາະບໍລີຫານ
  const Manager = [8, 9, 10, 119, 68, 21];

  // ກຳນົດພະເເນກ
  const Department = {
    // ພະເເນກບຸກຄະລາກອນ
    HR: 33,
    // ພະເເນກການເງີນ
    FINANCIAL: 58,
    // ພະເເນກລູກຄ້າສຳພັນ
    CRS: 45,
    // ພະເເນກບັນຊີ
    ACCOUNTING: 54,
  };

  return (
    <div>
      <div
        className="w-100 justify-content-start d-flex align-items-center"
        style={{ background: "#400a36", height: 50 }}
      >
        <IoReturnDownBackOutline
          className="ms-2 text-white"
          onClick={handleBackClick}
          size={35}
        />
      </div>
      <div className="mt-1 p-1">
        {loadingUser || loadingBranch || loadingCenter ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 9999,
              backdropFilter: "blur(5px)",
            }}
          >
            <img
              src={Loading}
              alt="Loading"
              style={{
                width: "100px",
                height: "auto",
              }}
            />
          </div>
        ) : null}

        <Card className="shadow p-1">
          <div>
            <div className="text-dark text-center">
              <h2>ກະລຸນາເລືອກຫົວຂໍ້</h2>
            </div>
            {userLogin?.role !== "CUSTOMER_SERVICE" &&
            Manager.includes(userLogin?.position?._id) ||
            userLogin?.department?._id === Department.HR ? (
              <div>
                <input
                  type="checkbox"
                  className="me-1"
                  onChange={() => {
                    {
                      setTypeCheck("MANAGER");
                      setSearchDepartment();
                      setCheckUser();
                    }
                  }}
                  checked={typeCheck === "MANAGER"}
                />
                ລະດັບບໍລິຫານ
              </div>
            ) : null}

            {userLogin?.department?._id === 33 ? (
              <>
                <div>
                  <input
                    type="checkbox"
                    className="me-1"
                    onChange={() => {
                      setTypeCheck("CALL_CENTER");
                    }}
                    checked={typeCheck === "CALL_CENTER"}
                  />
                  ວິຊາການ ປະຈຳສຳນັກງານໃຫຍ່
                </div>
                <div>
                  <input
                    type="checkbox"
                    className="me-1"
                    onChange={() => {
                      setTypeCheck("CALL_CENTER_CENTER");
                      setSearchDepartment();
                      setCheckUser();
                    }}
                    checked={typeCheck === "CALL_CENTER_CENTER"}
                  />
                  ວິຊາການ ສູນໂຊກໃຫຍ່
                </div>
              </>
            ) : (
              <>
                {userLogin?.branch?._id &&
                userLogin?.position?._id === 21 &&
                userLogin?.role !== "CUSTOMER_SERVICE" ? (
                  <div>
                    <input
                      type="checkbox"
                      className="me-1"
                      onChange={() => {
                        setTypeCheck("CALL_CENTER");
                      }}
                      checked={typeCheck === "CALL_CENTER"}
                    />
                    ວິຊາການ ປະຈຳສຳນັກງານໃຫຍ່
                  </div>
                ) : null}
                {userLogin?.center?.st_id && userLogin?.position?._id === 21 ? (
                  <div>
                    <input
                      type="checkbox"
                      className="me-1"
                      onChange={() => {
                        setTypeCheck("CALL_CENTER_CENTER");
                        setSearchDepartment();
                        setCheckUser();
                      }}
                      checked={typeCheck === "CALL_CENTER_CENTER"}
                    />
                    ວິຊາການ ສູນໂຊກໃຫຍ່
                  </div>
                ) : null}
              </>
            )}

            {userLogin?.position?._id !== 21 && 
              (userLogin?.position?._id === 28 ||
              (userLogin?.position?._id === 9 &&
                userLogin?.department?._id === 50) ||
              (userLogin?.position?._id === 10 &&
                userLogin?.department?._id === 50) ||
              userLogin?.department?._id === 33 ? (
                <div>
                  <input
                    type="checkbox"
                    className="me-1"
                    onChange={() => {
                      setTypeCheck("CENTER_IS_DAY");
                      setSearchDepartment();
                      setCheckUser();
                    }}
                    checked={typeCheck === "CENTER_IS_DAY"}
                  />
                  ລະດັບປະຕິບັດການ (ຄັດເເຍກ ກະເຊົ້າ)
                </div>
              ) : null)}

            {userLogin?.position?._id !== 21 && 
              (userLogin?.position?._id === 27 ||
              (userLogin?.position?._id === 9 &&
                userLogin?.department?._id === 50) ||
              (userLogin?.position?._id === 10 &&
                userLogin?.department?._id === 50) ||
              userLogin?.department?._id === 33 ? (
                <div>
                  <input
                    type="checkbox"
                    className="me-1"
                    onChange={() => {
                      setTypeCheck("CENTER_IS_NIGHT");
                      setSearchDepartment();
                      setCheckUser();
                    }}
                    checked={typeCheck === "CENTER_IS_NIGHT"}
                  />
                  ລະດັບປະຕິບັດການ (ຄັດເເຍກ ກະເເລງ)
                </div>
              ) : null)}

            {Hight_Agency.includes(userLogin?.position?._id) ||
            userLogin?.department?._id === Department.CRS ||
            userLogin?.department?._id === Department.HR ? (
              <>
                <div>
                  <input
                    type="checkbox"
                    className="me-1"
                    onChange={() => {
                      {
                        setTypeCheck("CENTER");
                        setSearchDepartment();
                        setCheckUser();
                      }
                    }}
                    checked={typeCheck === "CENTER"}
                  />
                  ສູນຕ່າງເເຂວງ ດີເດັ່ນ
                </div>
              </>
            ) : null}

            {Hight_Agency.includes(userLogin?.position?._id) ||
            userLogin?.department?._id === Department.FINANCIAL ||
            userLogin?.department?._id === Department.HR ||
            userLogin?.role === "CUSTOMER_SERVICE" ||
            userLogin?.department?._id === Department.ACCOUNTING ? (
              <>
                <div>
                  <input
                    type="checkbox"
                    className="me-1"
                    onChange={() => {
                      {
                        setTypeCheck("BRANCH");
                        setSearchDepartment();
                        setCheckUser();
                      }
                    }}
                    checked={typeCheck === "BRANCH"}
                  />
                  ສາຂາ ດີເດັ່ນ
                </div>
              </>
            ) : null}
          </div>
        </Card>
        {typeCheck !== "BRANCH" && typeCheck !== "CENTER" ? (
          <Card className="shadow p-1 mt-2">
            <SelectUser onChange={setSearchUser} typeCheck={typeCheck} />
          </Card>
        ) : null}
        <hr />
        {(typeCheck === "MANAGER" ||
          typeCheck === "CALL_CENTER" ||
          typeCheck === "CALL_CENTER_CENTER" ||
          typeCheck === "CENTER_IS_NIGHT" ||
          typeCheck === "CENTER_IS_DAY") &&
          item?.map(
            (item) =>
              item?._id !== userLogin?._id && (
                <Card className="shadow mb-1 p-1" key={item.id}>
                  <Row>
                    <Col className="col-10">
                      <div className="fw-bold text-center">
                        {item?.department?.title_lao}
                      </div>
                      <div className="text-dark">
                        {item?.position?._id === 9 ||
                        item?.position?._id === 10 ? (
                          <>
                            {item.position._id === 9
                              ? " ຫົວໜ້າ"
                              : item.position._id === 10
                              ? " ຮອງຫົວໜ້າ"
                              : null}
                            {""} {""} {""}
                          </>
                        ) : (
                          <>
                            {item.gender === "MALE" ? "ທ້າວ" : "ນາງ"} {""}
                          </>
                        )}
                        {""} {item?.firstName} {item?.lastName}
                      </div>
                    </Col>
                    <Col className="col-2 d-flex justify-content-center align-items-center">
                      <FromVote
                        typeCheck={typeCheck}
                        id_user={item}
                        onSuccess={setLoading}
                      />
                    </Col>
                  </Row>
                </Card>
              )
          )}
        {typeCheck === "CENTER" &&
          itemCenter?.map((item) => (
            <Card
              className="shadow mb-1 p-2"
              key={item.id}
              style={{ fontSize: 17 }}
            >
              <div className="d-flex">
                <span className="col-10 fw-bold text-start">
                  {item?.centerName}
                </span>
                <span className="col-2 d-flex justify-content-center">
                  <FromVote
                    typeCheck={typeCheck}
                    id_Center={item}
                    onSuccess={setLoading}
                  />
                </span>
              </div>
            </Card>
          ))}
        {typeCheck === "BRANCH" &&
          itemBranch?.map((item) => (
            <Card
              className="shadow mb-1 p-2"
              key={item.id}
              style={{ fontSize: 17 }}
            >
              <Row>
                <Col className="col-10">
                  <div className="fw-bold text-start">{item?.title}</div>
                </Col>
                <Col className="col-2 d-flex justify-content-center align-items-center">
                  <FromVote
                    typeCheck={typeCheck}
                    id_Branch={item}
                    onSuccess={setLoading}
                  />
                </Col>
              </Row>
            </Card>
          ))}
        <BackToTop />
      </div>
    </div>
  );
}
