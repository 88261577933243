import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import React, { useEffect, useState } from "react";
import "./index.css";
import { currency, loadingData } from "../../helper";

export default function OtherMoney({ data, loading }) {
  const [dataNote, setDataNote] = useState(0);
  const [dataInsuranceExpense, setInsuranceExpense] = useState(0);
  const [dataTaxIncome, setTaxIncome] = useState(0);
  const [livingSalary, setLivingSalary] = useState(0);
  const [allowance, setAllOwance] = useState(0);
  const [fuelMoney, setFuelMoney] = useState(0);
  const [basicSalary, setBasicSalary] = useState(0);

  useEffect(() => {
    setDataNote((data?.positionSalary * 100) / data?.basicSalary);
    setInsuranceExpense((data?.InsuranceExpense * 100) / data?.basicSalary);
    setTaxIncome((data?.taxIncome * 100) / data?.basicSalary);
    setLivingSalary((data?.livingSalary * 100) / data?.basicSalary);
    setAllOwance((data?.allowance * 100) / data?.basicSalary);
    setFuelMoney((data?.fuelMoney * 100) / data?.basicSalary);
    setBasicSalary((data?.basicSalary * 100) / data?.basicSalary);
  }, [data]);

  return (
    <>
      <div className="saving-goals-section pb-15">
        <div
          className="progress-card progress-card-red mb-15"
          style={{ boxShadow: "inset 0 0 1px red" }}
        >
          <div className="progress-card-info">
            <div style={{ width: 50, height: 50 }}>
              <CircularProgressbar
                value={basicSalary ? parseInt(basicSalary) : 0}
                text={`${basicSalary ? currency(basicSalary) : 0}%`}
                styles={buildStyles({
                  textColor: "red",
                  pathColor: "red",
                  trailColor: "#ebe4e6",
                })}
              />
            </div>
            <div className="progress-primary-text ms-2">
              <h3>ເງິນເດືອນພື້ນຖານ</h3>
            </div>
          </div>
          <div className="progress-card-amount">
            {loading ? (
              loadingData(25)
            ) : (
              <>
                <h3> {data?.basicSalary ? currency(data?.basicSalary) : 0} </h3>
              </>
            )}
          </div>
        </div>
        <div
          className="progress-card progress-card-red mb-15"
          style={{ boxShadow: "inset 0 0 1px red" }}
        >
          <div className="progress-card-info">
            <div style={{ width: 50, height: 50 }}>
              <CircularProgressbar
                value={dataNote ? parseInt(dataNote) : 0}
                text={`${dataNote ? currency(dataNote) : 0}%`}
                styles={buildStyles({
                  textColor: "red",
                  pathColor: "red",
                  trailColor: "#ebe4e6",
                })}
              />
            </div>
            <div className="progress-primary-text ms-2">
              <h3>ເງິນຕຳແຫນ່ງ</h3>
            </div>
          </div>
          <div className="progress-card-amount">
            {loading ? (
              loadingData(25)
            ) : (
              <>{data?.positionSalary ? currency(data?.positionSalary) : 0}</>
            )}
          </div>
        </div>
        <div
          className="progress-card progress-card-red mb-15"
          style={{ boxShadow: "inset 0 0 1px red" }}
        >
          <div className="progress-card-info">
            <div style={{ width: 50, height: 50 }}>
              <CircularProgressbar
                value={livingSalary ? parseInt(livingSalary) : 0}
                text={`${livingSalary ? currency(livingSalary) : 0}%`}
                styles={buildStyles({
                  textColor: "red",
                  pathColor: "red",
                  trailColor: "#ebe4e6",
                })}
              />
            </div>
            <div className="progress-primary-text ms-2">
              <h3>ເງິນອາຫານ</h3>
            </div>
          </div>
          <div className="progress-card-amount">
            {loading ? (
              loadingData(25)
            ) : (
              <>{data?.livingSalary ? currency(data?.livingSalary) : 0} </>
            )}
          </div>
        </div>

        <div
          className="progress-card progress-card-red mb-15"
          style={{ boxShadow: "inset 0 0 1px red" }}
        >
          <div className="progress-card-info">
            <div style={{ width: 50, height: 50 }}>
              <CircularProgressbar
                value={allowance ? parseInt(allowance) : 0}
                text={`${allowance ? currency(allowance) : 0}%`}
                styles={buildStyles({
                  textColor: "red",
                  pathColor: "red",
                  trailColor: "#ebe4e6",
                })}
              />
            </div>
            <div className="progress-primary-text ms-2">
              <h3>ເງິນອຸດໜຸນຄ່າຄອງຊີບ</h3>
            </div>
          </div>
          <div className="progress-card-amount">
            {loading ? (
              loadingData(25)
            ) : (
              <>{data?.allowance ? currency(data?.allowance) : 0} </>
            )}
          </div>
        </div>

        <div
          className="progress-card progress-card-red mb-15"
          style={{ boxShadow: "inset 0 0 1px red" }}
        >
          <div className="progress-card-info">
            <div style={{ width: 50, height: 50 }}>
              <CircularProgressbar
                value={fuelMoney ? parseInt(fuelMoney) : 0}
                text={`${fuelMoney ? currency(fuelMoney) : 0}%`}
                styles={buildStyles({
                  textColor: "red",
                  pathColor: "red",
                  trailColor: "#ebe4e6",
                })}
              />
            </div>
            <div className="progress-primary-text ms-2">
              <h3>ເງິນຄ່ານ້ຳມັນ</h3>
            </div>
          </div>
          <div className="progress-card-amount">
            {loading ? (
              loadingData(25)
            ) : (
              <>{data?.fuelMoney ? currency(data?.fuelMoney) : 0} </>
            )}
          </div>
        </div>

        <div
          className="progress-card progress-card-blue mb-15"
          style={{ boxShadow: "inset 0 0 1px red" }}
        >
          <div className="progress-card-info">
            <div className="circular-progress" data-note={25}>
              <div style={{ width: 50, height: 50 }}>
                <CircularProgressbar
                  value={dataTaxIncome ? parseInt(dataTaxIncome) : 0}
                  text={`${dataTaxIncome ? currency(dataTaxIncome) : 0}%`}
                />
              </div>
            </div>
            <div className="progress-info-text">
              <h3>ເງິນອາກອນ</h3>
            </div>
          </div>
          <div className="progress-card-amount">
            {loading ? (
              loadingData(25)
            ) : (
              <>{data ? currency(data?.taxIncome) : 0} </>
            )}
          </div>
        </div>

        <div
          className="progress-card progress-card-green mb-15"
          style={{ boxShadow: "inset 0 0 1px red" }}
        >
          <div className="progress-card-info">
            <div className="circular-progress" data-note={75}>
              <div style={{ width: 50, height: 50 }}>
                <CircularProgressbar
                  value={
                    dataInsuranceExpense ? parseInt(dataInsuranceExpense) : 0
                  }
                  text={`${
                    dataInsuranceExpense ? currency(dataInsuranceExpense) : 0
                  }%`}
                  styles={buildStyles({
                    textColor: "black",
                    pathColor: "#52ba5e",
                    trailColor: "#ebe4e6",
                  })}
                />
              </div>
            </div>
            <div className="progress-info-text">
              <h3>ເງິນປະກັນສັງຄົມ</h3>
            </div>
          </div>
          <div className="progress-card-amount">
            {loading ? (
              loadingData(25)
            ) : (
              <>{data ? currency(data?.InsuranceExpense) : 0} </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
