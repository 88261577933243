import { useState, useEffect } from 'react';
import { IoMdArrowRoundUp } from 'react-icons/io';

const BackToTop = () => {
  const [displayButton, setDisplayButton] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleScroll = () => {
    if (window.pageYOffset > 20) {
      setDisplayButton(true);
    } else {
      setDisplayButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <button
      onClick={scrollToTop}
      id="myBtn"
      title="Go to top"
      style={{
        display: displayButton ? 'block' : 'none',
        position: 'fixed',
        bottom: '20px',
        right: '30px',
        zIndex: '99',
        fontSize: '18px',
        border: 'none',
        outline: 'none',
        background: '#400b36',
        color: 'white',
        cursor: 'pointer',
        padding: '10px',
        borderRadius: '20px',
      }}
    >
      <i className="icon-container">
        <IoMdArrowRoundUp />
      </i>
    </button>
  );
};

export default BackToTop;
