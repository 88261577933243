import { useRef, useState } from "react";
import { AiOutlineClear } from "react-icons/ai";
import SignatureCanvas from "react-signature-canvas";
import CompressImage from "../../../until/UploadFIle";
import { LiaSignatureSolid } from "react-icons/lia";
import { Modal } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { IoCloseSharp } from "react-icons/io5";
import { gcpUpload, messageError, messageWarning } from "../../../helper";

export default function SignatureEmployee({
    setImageURL,
    setFile,
}) {
    const sigCanvas = useRef();
    const [show, setShow] = useState(false);
    const clear = () => sigCanvas.current?.clear();
    const [newFilename] = useState("");
    const save = async () => {
        try {
            const trimmedCanvas = sigCanvas.current?.getTrimmedCanvas();
            if (trimmedCanvas) {
                const signatureData = trimmedCanvas.toDataURL("image/png");
                setImageURL(signatureData);
            } else {
                setImageURL(null);
            }
            const signatureData = sigCanvas.current?.toDataURL("image/png");
            const actualBase64String = signatureData?.split(",")[1];
            const binaryString = atob(actualBase64String || "");
            const arrayBuffer = new ArrayBuffer(binaryString.length);
            const uint8Array = new Uint8Array(arrayBuffer);
            for (let i = 0; i < binaryString.length; i++) {
                uint8Array[i] = binaryString.charCodeAt(i);
            }
            const blob = new Blob([uint8Array], { type: "image/png" });
            const file = new File([blob], "image.png", { type: "image/png" });
            const compressedFile = await CompressImage(file);
            const reader = new FileReader();
            reader.onload = async (e) => {
                if (!blob) return messageWarning("ກະລຸນາເຊັນລາຍເຊັນກອນ");
                setImageURL(e.target?.result);
                const formData = new FormData();
                formData.append("file", compressedFile);
                const res = await gcpUpload({
                    file: compressedFile,
                    bucketName: "anousith-bucket",
                    pathname: "employesImage",
                    filename: newFilename,
                });
                if (res?.publicUrl) {
                    setFile(res?.filename);
                }
                setShow(true);
            };
            reader.readAsDataURL(compressedFile);
        } catch (error) {
            console.log(error);
            messageError("ອັບໂຫຼດບໍ່ສຳເລັດ");
        }
    };

    return (
        <div>
            <Row>
                <Col className="col-12 text-center">
                    <button
                        type="button"
                        className="btn btn-dark me-2 shadow-none"
                        onClick={() => setShow(true)}
                        style={{ position: "relative", top: "0px" }}
                    >
                        <LiaSignatureSolid size={30} />
                    </button>
                </Col>
            </Row>
            <Modal show={show} animation={false} size="lg">
                <div>
                    <Row>
                        <Col className="col-8 ms-4">
                            <h2>ລາຍເຊັນຜູ້ສະເໜີ</h2>
                        </Col>
                        <Col className="col-2 ms-4 text-right">
                            <span onClick={() => setShow(false)}><IoCloseSharp size={30} /></span>
                        </Col>
                    </Row>
                </div>
                <Modal.Body>
                    <SignatureCanvas
                        ref={sigCanvas}
                        canvasProps={{
                            className: "signatureCanvas",
                            style: {
                                width: "100%",
                                height: "320px",
                                border: "1px solid black",
                                backgroundColor: "white",
                            },
                        }}
                    />
                </Modal.Body>
                <div className="d-flex flex-row justify-content-end">
                    <button
                        type="button"
                        className="btn btn-light hvr-shutter-out-horizontal me-2"
                        onClick={clear}
                    >
                        <AiOutlineClear size={25} /> ລ້າງລາຍເຊັນ
                    </button>
                    <button
                        type="button"
                        className="btn btn-success hvr-shutter-out-horizontal me-3"
                        onClick={save}
                    >
                        <i className="icon-check-circle me-1" />
                        <span className="ms-1">ບັນທຶກ</span>
                    </button>
                </div>
            </Modal>
        </div>
    );
}