// import { useLazyQuery } from "@apollo/client";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import { messageError, messageSuccess } from "../../helper";
import { UPDATE_EMPLOYEES } from "./apollo";
import { useMutation } from "@apollo/client";
export default function ChangePassword({ getData, loadData, data }) {
  //form state
  const [show, setShow] = useState(false);
  const [fetchPassword] = useMutation(UPDATE_EMPLOYEES);
  const handleClose = () => setShow(false);

  return (
    <Formik
      initialValues={{
        password: "",     
      }}
      enableReinitialize={true}
      validate={(values) => {
        const errors = {};
        if (!values.password) {
          errors.password = "ປ້ອນລະຫັດຜ່ານ";
        }   
				return errors;   
      }}
      onSubmit={async (values) => {
        try {
          let _updateAccount = await fetchPassword({
            variables: {
              data: {
                password: values.password,
              },
              where: {
                _id: parseInt(data?._id),
              },
            },
          });
          if (_updateAccount) {
            messageSuccess("ການດຳເນີນງານສຳເລັດ");
            setShow(false);
            getData(loadData === false ? true : false);
          } else {
            messageError("ການດຳເນີນງານບໍ່ສຳເລັດ");
          }
        } catch (error) {
          messageError("ການບັນທືກຂໍ້ມູນຜິດພາດ");
        }
      }}
    >
      {({ errors, handleChange, handleSubmit }) => (
        <React.Fragment>
          <span
            type="button"
            className=" mr-2 text-success"
            onClick={() => setShow(true)}
          >
            <i className="icon-edit" />
            ປ່ຽນລະຫັດຜ່ານ
          </span>
          <Modal
            centered
            show={show}
            onHide={() => setShow(false)}
            animation={false}
            backdrop="static"
            size="lg"
          >
            <Modal.Header>
              <Modal.Title className="fs-5">
                <i className="icon-sliders" /> ແກ້ໄຂລະຫັດຜ່ານ
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group mb-3">
                <label>ຊື່ ແລະ ນາມສະກຸນ</label>
                <input
                  type="text"
                  className="form-control"
                  name="firstName"
                  disabled
                  defaultValue={data?.firstName + " " + data?.lastName ?? ""}
                  onChange={handleChange}
                  placeholder="ປ້ອນຊື່ບັນຊີ"
                />
              </div>
              <div className="form-group mb-3">
                <label>ເບີໂທ</label>
                <input
                  type="text"
                  className="form-control"
                  name="phoneNumber"
									disabled
                  defaultValue={data?.phoneNumber ? data?.phoneNumber : ""}
                  onChange={handleChange}
                  placeholder="ປ້ອນຊື່ບັນຊີ"
                />
              </div>
              <div className="form-group mb-3">
                <label>ລະຫັດຜ່ານ</label>
                <input
                  type="text"
                  className={
                    errors.password
                      ? "form-control mb-3 is-invalid"
                      : "form-control mb-3 invalid"
                  }
                  name="password"
                  placeholder="ປ້ອນລະຫັດຜ່ານ"         
                  onChange={handleChange}
                />
                <i className="text-danger">{errors.password}</i>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-primary btn-block btn-sm"
                onClick={() => handleSubmit()}
              >
                <i className="icon-edit" style={{ marginRight: 3 }} />
                ແກ້ໄຂ
              </button>
              <button
                className="btn btn-danger btn-block btn-sm"
                onClick={() => handleClose()}
              >
                <i className="icon-x" style={{ marginRight: 3 }} />
                ປິດ
              </button>
            </Modal.Footer>
          </Modal>
        </React.Fragment>
      )}
    </Formik>
  );
}
