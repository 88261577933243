export const HOME_PAGE = "/home";
export const LOGIN = "/login";
export const HISTORY = "/history";
export const PROFILES = "/profile";
export const DETAIL_MONEY = "/detail_money";
export const VERIFY_OPT = "/verify";
export const MENU = "/menu";
export const ABSENCE = "/absence";
export const HOME_VOTE = "/home_vote";
export const HISTORY_ABSENCE = "/history_absence";
export const APPROVE = "/approve"