import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useLazyQuery, useMutation } from "@apollo/client";
import Schema from "../Apollo";
import {
  getStaffLogin,
  messageError,
  messageSuccess,
  messageWarning,
} from "../../../helper";
import { IoCloseSharp } from "react-icons/io5";
import IconVote from "../../../img/vote.png";
import vote from "../../../img/button.png";

export default function FromVote({
  typeCheck,
  id_Center,
  id_Branch,
  id_user,
  onSuccess,
}) {
  const user = getStaffLogin();
  const [title, setTitle] = useState([]);
  const [itemEvaluationUser, setItemEvaluationUser] = useState({});
  const [itemEvaluationBranch, setItemEvaluationBranch] = useState({});
  const [itemEvaluationCenter, setItemEvaluationCenter] = useState({});
  const [selectGroup, setSelectGroup] = useState({});
  const [show, setShow] = useState(false);
  const [created, { loading }] = useMutation(Schema.CREATED_VALUATION);
  const [FetchData, { data }] = useLazyQuery(Schema.EVALUATION_TITLE, {
    fetchPolicy: "cache-and-network",
  });

  const [
    FetchDatEvaluationUser,
    { data: dataEvaluationUser, loading: loadingUser },
  ] = useLazyQuery(Schema.EVALUATION, {
    fetchPolicy: "cache-and-network",
  });

  const [FetchDatEvaluationBranch, { data: dataEvaluationBranch }] =
    useLazyQuery(Schema.EVALUATION, {
      fetchPolicy: "cache-and-network",
    });
    
  const [FetchDatEvaluationCenter, { data: dataEvaluationCenter }] =
    useLazyQuery(Schema.EVALUATION, {
      fetchPolicy: "cache-and-network",
    });

  useEffect(() => {
    FetchDatEvaluationUser({
      variables: {
        where: {
          user_id: id_user?._id,
          created_by: Number(user._id),
        },
      },
    });
  }, [id_user, loading]);

  useEffect(() => {
    FetchData({
      variables: {
        where: {},
      },
    });

    FetchDatEvaluationBranch({
      variables: {
        where: {
          branch_id: id_Branch?._id,
          created_by: Number(user._id),
        },
      },
    });
    FetchDatEvaluationCenter({
      variables: {
        where: {
          center_id: id_Center?.st_id,
          created_by: Number(user._id),
        },
      },
    });
  }, [loading, typeCheck]);

  const getTypeCheck = (typeCheck) => {
    if (typeCheck === "CENTER_IS_NIGHT") {
      return "CENTER_IS_DAY";
    } else if (typeCheck === "CALL_CENTER_CENTER") {
      return "CALL_CENTER";
    } else {
      return typeCheck;
    }
  };

  useEffect(() => {
    setTitle(data?.evaluationTitle?.data || []);
    setItemEvaluationUser(dataEvaluationUser?.evaluation?.data[0] || []);
    setItemEvaluationBranch(dataEvaluationBranch?.evaluation?.data[0] || []);
    setItemEvaluationCenter(dataEvaluationCenter?.evaluation?.data[0] || []);
  }, [data, dataEvaluationUser, dataEvaluationBranch, dataEvaluationCenter]);

  const handleClick = async (values) => {
    if (!selectGroup || Object.keys(selectGroup).length === 0) {
      return messageWarning("ກະລຸນາເລືອກກອນ");
    }
    try {
      const selectGroupData = Object.entries(selectGroup).map(
        ([
          groupName,
          { point, id_Title, type, id_user, branch_id, center_id },
        ]) => ({
          groupName: groupName,
          point: parseInt(point),
          id_Title: id_Title,
          id_user: id_user,
          type: type,
          branch_id: branch_id,
          center_id: center_id,
        })
      );
      await created({
        variables: {
          data: {
            selectGroup: selectGroupData,
          },
        },
      });
      messageSuccess("ການດຳເນີນງານສຳເລັດ");
      onSuccess(loading);
      setShow(false);
    } catch (error) {
      console.log(error);
      messageError("ມີຂໍ້ມູນບາງຢ່າງຜິດພາດ");
    }
  };

  const handleChange = (
    e,
    groupName,
    id_Title,
    type,
    id_user,
    branch_id,
    center_id
  ) => {
    const value = e.target.value;
    setSelectGroup((prev) => ({
      ...prev,
      [id_Title]: {
        point: value,
        id_Title: id_Title,
        groupName: groupName,
        type: type,
        id_user: id_user ? parseInt(id_user) : undefined,
        branch_id: branch_id ? parseInt(branch_id) : undefined,
        center_id: center_id ? parseInt(center_id) : undefined,
      },
    }));
  };

  const renderRadioGroup = (
    titleHeader,
    id_Title,
    options,
    groupName,
    type,
    id_user,
    id_Branch,
    id_Center
  ) => (
    <Form.Group className="mb-1">
      <h5>{titleHeader}</h5>
      {options.map((item) => (
        <div key={item.value}>
          <input
            type="radio"
            name={groupName}
            id={`radio-${groupName}-${item.value}`}
            value={item.value}
            checked={selectGroup[id_Title]?.point === String(item.value)}
            onChange={(e) =>
              handleChange(
                e,
                groupName,
                id_Title,
                type,
                id_user,
                id_Branch,
                id_Center
              )
            }
          />
          <label htmlFor={`radio-${groupName}-${item.value}`}>
            <span className="ms-1">{item.label}</span>
          </label>
        </div>
      ))}
    </Form.Group>
  );

  const options = [
    { value: 5, label: "ດີຫຼາຍ (5 ຄະເເນນ)" },
    { value: 4, label: "ດີ (4 ຄະເເນນ)" },
    { value: 3, label: "ປານກາງ (3 ຄະເເນນ)" },
    { value: 2, label: "ພໍໃຊ້ (2 ຄະເເນນ)" },
    { value: 1, label: "ອ່ອນ (1 ຄະເເນນ)" },
  ];

  // ກຳນົດພະເເນກ
  const Department = {
    // ພະເເນກບຸກຄະລາກອນ
    HR: 33,
    // ພະເເນກການເງີນ
    FINANCIAL: 58,
    // ພະເເນກລູກຄ້າສຳພັນ
    CRS: 45,
    // ພະເເນກຂົນສົ່ງ
    TRANSPORTATION: 52,
    // ພະເເນກ
    WAREHOUSE_CONTROL: 52,
    // ພະເເນກບັນຊີ
    ACCOUNTING: 54,
  };

  // ກຳນົດຕຳເເຫນ່ງ
  const Position = {
    DIRECTOR: 8,
    DEPARTMENT_HEAD: 9,
    DEPUTY_HEAD: 10,
    HEAD_OF_AGENCY: 15,
    ACADEMIC: 21,
    CONSULTING: 68,
    ACCOUNT_DIRECTOR: 119,
  };

  // ຫົວໜ້າໝ່ວຍງານ 15, ຫົວໜ້າພະເເນກ 9, ຮອງຫົວໜ້າພະເເນກ 10, ຫົວໜ້າສູນຄັດເເຍກ 29, ຫົວໜ້າໜ່ວຍງານກະເຊົ້າ 41, ຫົວໜ້າໜ່ວຍງານກະເເລງ 44, ຫົວໜ້າເເຂວງ 17, ຫົວໜ້າຈຸກະເຊົ້າ 47,
  // ຫົວໜ້າຈຸກະເເລ 48, ຫົວໜ້າຈຸບໍລິຫານລົດບໍລິສັດກະເຊົ້າ 94, ຫົວໜ້າຈຸບໍລິຫານລົດບໍລິສັດກະເເລງ 95, ຫົວໜ້າຈຸຕີດຕາມລົດຮ່ວມກະເຊົ້າ 98, ຫົວໜ້າຈຸຕີດຕາມລົດຮ່ວມກະເເລງ 99, ຜູ້ອຳນວຍການ ບັນຊີ-ການເງີນ 119
  // ທີ່ປຶກສາບໍລິສັດ 68, ວິຊາການ 21, ຜູ້ອຳນວຍການໃຫຍ່ 8, ຫົວໜ້າສາຂາ 18

  //ສິດທີ່ໃຫຍ່ກວ່າຫຼືເທົ່າກັບຫົວໜ້າໜ່ວຍງານ
  const Hight_Agency = [9, 10, 119, 68];
  //ສິດທີ່ໃຫຍ່ກວ່າຮອງພະເເນກຂຶ້ນໄປຍົກເວັນຫົວໜ້າເເຂວງ
  const Hight_Deputy_Not_Head_Province = [8, 9, 10, 18, 17, 68, 119];
  //ສິດທີ່ໃຫຍ່ກວ່າຮອງພະເເນກຂຶ້ນໄປຍົກເວັນຫົວໜ້າສາຂາ
  const Hight_Deputy_Not_Head_Branch = [8, 9, 10, 17, 68, 119];

  const canVoteCenter =
    (getTypeCheck(typeCheck) === "CENTER" &&
      user?.department?._id === Department.HR) ||
    user?.position?._id === Position.DEPARTMENT_HEAD ||
    user?.position?._id === Position.DEPUTY_HEAD ||
    user?.position?._id === Position.DIRECTOR ||
    user?.position?._id === Position.CONSULTING ||
    user?.position?._id === Position.ACCOUNT_DIRECTOR ||
    user?.department?._id === Department.CRS ||
    (user?.role === "CUSTOMER_SERVICE" && user?.branch?._id);

  const canVoteBranch =
    (getTypeCheck(typeCheck) === "BRANCH" &&
      user?.department?._id === Department.HR) ||
    user?.position?._id === Position.DEPARTMENT_HEAD ||
    user?.position?._id === Position.DEPUTY_HEAD ||
    user?.position?._id === Position.DIRECTOR ||
    user?.position?._id === Position.CONSULTING ||
    user?.department?._id === Department.FINANCIAL ||
    user?.department?._id === Department.ACCOUNTING ||
    user?.position?._id === Position.ACCOUNT_DIRECTOR ||
    (user?.role === "CUSTOMER_SERVICE" && user?.branch?._id);

  const isNotOwnerOrSameUserCenter =
    itemEvaluationCenter?.created_by?._id !== user?._id &&
    itemEvaluationCenter?.center_id?._id !== id_Center?.st_id;

  const isNotOwnerOrSameUserBranch =
    itemEvaluationBranch?.created_by?._id !== user?._id &&
    itemEvaluationBranch?.branch_id?._id !== id_Branch?._id;

  return (
    <div>
      {!loadingUser &&
      itemEvaluationUser?.created_by?._id !== user?._id &&
      itemEvaluationUser?.user_id?._id !== id_user?._id &&
      user?.position?._id !== Position?.DIRECTOR &&
      user?.role !== "CUSTOMER_SERVICE" ? (
        <>
          {/* ກວດສອບເງື່ອນໄຂການໂຫວດ ຕ້ອງຕ່າງຈາກຜູ້ອຳນວຍການໃຫຍ່ */}
          <img
            src={vote}
            width={100}
            alt="Voting Icon"
            onClick={() => setShow(true)}
          />
        </>
      ) : !loadingUser &&
        itemEvaluationUser?.created_by?._id !== user?._id &&
        itemEvaluationUser?.user_id?._id !== id_user?._id &&
        getTypeCheck(typeCheck) === "MANAGER" &&
        user?.position?._id === Position?.DIRECTOR ? (
        <>
          {/* ກວດສອບເງື່ອນໄຂ ຕ້ອງເປັນຜ້ອຳນວຍການໃຫຍ່ເທົ່ານັ້ນທີເຫັນປຸ່ມ */}
          <img
            src={vote}
            width={100}
            alt="Voting Icon"
            onClick={() => setShow(true)}
          />
        </>
      ) : null}

      {!(user?.role === "CUSTOMER_SERVICE" && user?.branch?._id) &&
      canVoteCenter &&
      isNotOwnerOrSameUserCenter ? (
        <img
          src={vote}
          width={100}
          alt="Voting Icon"
          onClick={() => setShow(true)}
        />
      ) : null}

      {canVoteBranch && isNotOwnerOrSameUserBranch ? (
        <img
          src={vote}
          width={100}
          alt="Voting Icon"
          onClick={() => setShow(true)}
        />
      ) : null}

      <div>
        <Modal
          show={show}
          size="xl"
          style={{ maxHeight: "700px", marginTop: "25%" }}
        >
          <Modal.Body style={{ maxHeight: "700px", overflowY: "auto" }}>
            <div>
              <h3 className="text-center mb-3">
                <span className="float-start">
                  <img src={IconVote} width={30} />{" "}
                </span>
                <b>ຟອມໃຫ້ຄະເເນນ</b>
                <span className="float-end">
                  <IoCloseSharp
                    className="cursor-pointer"
                    onClick={() => {
                      setShow(false);
                      setSelectGroup({});
                    }}
                  />
                </span>
              </h3>
            </div>
            <Form>
              {id_user ? (
                <div className="text-center">
                  <h3 className="d-flex justify-content-center">
                    {id_user.position?._id === 9 ? (
                      <>
                        ຫົວໜ້າ {id_user.firstName} {id_user.lastName}
                      </>
                    ) : id_user.position?._id === 10 ? (
                      <>
                        ຮອງຫົວໜ້າ {id_user.firstName} {id_user.lastName}
                      </>
                    ) : (
                      <>
                        {id_user.gender === "MALE" ? "ທ້າວ" : "ນາງ"}{" "}
                        {id_user.firstName} {id_user.lastName}
                      </>
                    )}
                  </h3>
                </div>
              ) : null}

              {id_Branch ? (
                <h3 className="d-flex justify-content-center">
                  {id_Branch?.title}
                </h3>
              ) : null}

              {id_Center ? (
                <h3 className="d-flex justify-content-center">
                  {id_Center?.centerName}
                </h3>
              ) : null}

              <hr />
              <div>
                <div className="col-12 ">
                  <Form.Group className="mt-2">
                    {/* ການບໍລິຫານ ໂດຍລວມ */}
                    {getTypeCheck(typeCheck) === "MANAGER" &&
                      user?.position?._id !== Position?.DIRECTOR &&
                      user?.position?._id !== Position?.CONSULTING &&
                      user?.position?._id !== Position?.ACCOUNT_DIRECTOR &&
                      renderRadioGroup(
                        <h3>● {title[0]?.title}</h3>,
                        title[0]?._id,
                        options,
                        1,
                        String(typeCheck),
                        id_user?._id
                      )}
                    {/* ຄວາມເອົາໃຈໃສ່ໃນການປະຕິບັດໝ້າທີ່ຮັບຜິດຊອບ */}
                    {getTypeCheck(typeCheck) === "MANAGER" &&
                    (user?.position?._id === Position?.DIRECTOR ||
                      user?.position?._id === Position?.CONSULTING ||
                      user?.position?._id === Position?.ACCOUNT_DIRECTOR)
                      ? renderRadioGroup(
                          <h3>● {title[1]?.title}</h3>,
                          title[1]?._id,
                          options,
                          2,
                          String(typeCheck),
                          id_user?._id
                        )
                      : (getTypeCheck(typeCheck) === "CALL_CENTER" ||
                          getTypeCheck(typeCheck) === "CENTER_IS_DAY") &&
                        Hight_Agency.includes(user.position._id) &&
                        renderRadioGroup(
                          <h3>● {title[1]?.title}</h3>,
                          title[1]?._id,
                          options,
                          2,
                          String(typeCheck),
                          id_user?._id
                        )}
                    {/* ຄວາມສາມັກຄິພາຍໃນອົງກອນ */}
                    {getTypeCheck(typeCheck) === "MANAGER" &&
                    user?.position?._id !== Position?.DIRECTOR &&
                    user?.position?._id !== Position?.CONSULTING &&
                    user?.position?._id !== Position?.ACCOUNT_DIRECTOR
                      ? renderRadioGroup(
                          <h3>● {title[2]?.title}</h3>,
                          title[2]?._id,
                          options,
                          3,
                          String(typeCheck),
                          id_user?._id
                        )
                      : Hight_Agency.includes(user.position._id) &&
                        (getTypeCheck(typeCheck) === "CENTER_IS_DAY" ||
                          getTypeCheck(typeCheck) === "CALL_CENTER")
                      ? renderRadioGroup(
                          <h3>● {title[2]?.title}</h3>,
                          title[2]?._id,
                          options,
                          3,
                          String(typeCheck),
                          id_user?._id
                        )
                      : null}
                    {/* ລະບຽບໂມງເວລາ ມາປະຕິບັດວຽກ */}
                    {(getTypeCheck(typeCheck) === "MANAGER" ||
                      getTypeCheck(typeCheck) === "CALL_CENTER" ||
                      getTypeCheck(typeCheck) === "CENTER_IS_DAY") &&
                      user?.department?._id === Department?.HR &&
                      renderRadioGroup(
                        <h3>● {title[3]?.title}</h3>,
                        title[3]?._id,
                        options,
                        4,
                        String(typeCheck),
                        id_user?._id
                      )}
                    {/* ລະບຽບການນຸ່ງຖື */}
                    {(getTypeCheck(typeCheck) === "MANAGER" ||
                      getTypeCheck(typeCheck) === "CALL_CENTER" ||
                      getTypeCheck(typeCheck) === "CENTER_IS_DAY") &&
                      user?.department?._id === Department?.HR &&
                      renderRadioGroup(
                        <h3>● {title[4]?.title}</h3>,
                        title[4]?._id,
                        options,
                        5,
                        String(typeCheck),
                        id_user?._id
                      )}
                    {/* ຄະເເນນ ຈາກ ເພື່ອນຮ່ວມງານ */}
                    {(getTypeCheck(typeCheck) === "CALL_CENTER" ||
                      getTypeCheck(typeCheck) === "CENTER_IS_DAY") &&
                      renderRadioGroup(
                        <h3>● {title[5]?.title}</h3>,
                        title[5]?._id,
                        options,
                        6,
                        String(typeCheck),
                        id_user?._id
                      )}
                    {/* ການບໍລິຫານ ພາຍໃນສູນ */}
                    {getTypeCheck(typeCheck) === "CENTER" &&
                      Hight_Deputy_Not_Head_Branch.includes(
                        user.position._id
                      ) &&
                      renderRadioGroup(
                        <h3>● {title[6]?.title}</h3>,
                        title[6]?._id,
                        options,
                        7,
                        String(typeCheck),
                        id_user?._id,
                        id_Branch?._id,
                        id_Center?.st_id
                      )}
                    {/* ການຄັດເເຍກເຄື່ອງ */}
                    {getTypeCheck(typeCheck) === "CENTER" &&
                      Hight_Deputy_Not_Head_Branch.includes(
                        user.position._id
                      ) &&
                      renderRadioGroup(
                        <h3>● {title[7]?.title}</h3>,
                        title[7]?._id,
                        options,
                        8,
                        String(typeCheck),
                        id_user?._id,
                        id_Branch?._id,
                        id_Center?.st_id
                      )}
                    {/* ເຄື່ອງລູກຄ້າເສຍຫາຍ */}
                    {getTypeCheck(typeCheck) === "CENTER" &&
                      user?.department?._id === Department?.CRS &&
                      renderRadioGroup(
                        <h3>● {title[8]?.title}</h3>,
                        title[8]?._id,
                        options,
                        9,
                        String(typeCheck),
                        id_user?._id,
                        id_Branch?._id,
                        id_Center?.st_id
                      )}
                    {/* ການປະສານງານຕ່າງໆ */}
                    {getTypeCheck(typeCheck) === "CENTER" &&
                      Hight_Deputy_Not_Head_Branch.includes(
                        user.position._id
                      ) &&
                      renderRadioGroup(
                        <h3>● {title[9]?.title}</h3>,
                        title[9]?._id,
                        options,
                        10,
                        String(typeCheck),
                        id_user?._id,
                        id_Branch?._id,
                        id_Center?.st_id
                      )}
                    {/* ການປະຕິບັດລະບຽບ ພາຍໃນບໍລິສັດ */}
                    {(getTypeCheck(typeCheck) === "CENTER" ||
                      getTypeCheck(typeCheck) === "BRANCH") &&
                      user?.department?._id === Department?.HR &&
                      renderRadioGroup(
                        <h3>● {title[10]?.title}</h3>,
                        title[10]?._id,
                        options,
                        11,
                        String(typeCheck),
                        id_user?._id,
                        id_Branch?._id,
                        id_Center?.st_id
                      )}
                    {/* ການບໍລິການ ລູກຄ້າ */}
                    {getTypeCheck(typeCheck) === "BRANCH" &&
                      Hight_Deputy_Not_Head_Province.includes(
                        user.position._id
                      ) &&
                      renderRadioGroup(
                        <h3>● {title[11]?.title}</h3>,
                        title[11]?._id,
                        options,
                        12,
                        String(typeCheck),
                        id_user?._id,
                        id_Branch?._id
                      )}
                    {/* ການເເກ້ໄຂວຽກງານສະເພາະໜ້າ */}
                    {getTypeCheck(typeCheck) === "BRANCH" &&
                      Hight_Deputy_Not_Head_Province.includes(
                        user.position._id
                      ) &&
                      renderRadioGroup(
                        <h3>● {title[12]?.title}</h3>,
                        title[12]?._id,
                        options,
                        13,
                        String(typeCheck),
                        id_user?._id,
                        id_Branch?._id
                      )}
                    {/* ການມອບເງີນ COD */}
                    {getTypeCheck(typeCheck) === "BRANCH" &&
                      user?.department?._id === Department.FINANCIAL &&
                      renderRadioGroup(
                        <h3>● {title[13]?.title}</h3>,
                        title[13]?._id,
                        options,
                        14,
                        String(typeCheck),
                        id_user?._id,
                        id_Branch?._id
                      )}
                    {/* ຄວາມສາມັກຄີພາຍໃນສາຂາ */}
                    {/* {getTypeCheck(typeCheck) === "BRANCH" &&
                      user?.department?._id === Department.HR &&
                      renderRadioGroup(
                        <h3>● {title[14]?.title}</h3>,
                        title[14]?._id,
                        options,
                        15,
                        String(typeCheck),
                        id_user?._id,
                        id_Branch?._id
                      )} */}
                    {/* ການປະສານງານຕ່າງໆ */}
                    {(getTypeCheck(typeCheck) === "BRANCH" &&
                      user?.role === "CUSTOMER_SERVICE") ||
                    user?.department?._id === Department.ACCOUNTING
                      ? renderRadioGroup(
                          <h3>● {title[15]?.title}</h3>,
                          title[15]?._id,
                          options,
                          16,
                          String(typeCheck),
                          id_user?._id,
                          id_Branch?._id
                        )
                      : null}
                  </Form.Group>
                </div>
              </div>
              <div className="mt-2">
                <Button
                  className="btn btn-primary w-100 mb-1"
                  onClick={handleClick}
                  disabled={loading || Object.keys(selectGroup).length === 0}
                >
                  <i className="fa fa-save me-1" />
                  ຢືນຍັນ
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}
