import { Modal, Button, Row, Col } from "react-bootstrap";
import { FaRegEyeSlash } from "react-icons/fa";
import { RxEyeNone } from "react-icons/rx";

const ModalListVote = ({ show, setShow, dataToShow, modalTitle }) => {
  const handleClose = () => {
    setShow(false);
  };
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal"
        style={{ marginTop: "100px" }}
      >
        <Modal.Body>
          <h2 className="text-center mb-3">{modalTitle}</h2>
          <Row className="mb-3">
            <Col className="text-center font-weight-bold">ອັນດັບ</Col>
            <Col className="text-center font-weight-bold">ຊື່</Col>
            <Col className="text-center font-weight-bold">ຄະແນນໂຫວດ</Col>
          </Row>
          {dataToShow?.map((item, index) => (
            <Row key={item?.user_id || item?.center_id} className="text-center">
              <Col className="col-3 text-center">
                <h4>{index + 1}</h4>
              </Col>
              <Col className="col-5 text-center">
                <h4>
                  <FaRegEyeSlash />
                </h4>
              </Col>
              <Col className="col-4 text-center">
                <h4>{item?.point} ຄະເເນນ</h4>
              </Col>
            </Row>
          ))}
          <div className="d-flex justify-content-center mt-2">
            <button
              className="btn-success"
              style={{ borderRadius: "9px" }}
              onClick={handleClose}
            >
              <div className="me-2 ms-2">ປີດ</div>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default ModalListVote;
