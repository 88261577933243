import React, { useEffect } from "react";
import { Loading } from "notiflix";
import useReactRouter from "use-react-router";
import { useFormik } from "formik";
import ImgOTp from "../../img/otp.jpeg";
import { useMutation } from "@apollo/client";
import "../login/login.css";
import { TOKEN_OTP, messageError, messageWarning, TOKEN } from "../../helper/";
import { EMPLOYEE_OTP } from "./gql";
import { HOME_PAGE } from "../../routes/app";
export default function VerifyLogin() {
  const { history } = useReactRouter();
  const [loginUser] = useMutation(EMPLOYEE_OTP);
  const _otpToken = localStorage.getItem(TOKEN_OTP);
  const _clearLocal = async () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
    window.location.replace("/login");
  };
  useEffect(() => {
    if (!_otpToken) {
      return history.push("/login");
    }
  }, []);
  const { handleChange, values, errors, submitForm } = useFormik({
    initialValues: {
      otp: "",
    },
    enableReinitialize: true,
    validate: (values) => {
      const errors = {};
      if (!values.otp) errors.otp = "ກະລຸນາປ້ອນເບີໂທ";
      else if (String(values.otp).length < 6) {
        errors.otp = "ລະຫັດ otp ຕ້ອງເປັນ 6 ຕົວເລກ";
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        let user = await loginUser({
          variables: {
            data: {
              otp: parseInt(values.otp),
              otpToken: _otpToken,
            },
          },
        });
        if (user) {
          localStorage.setItem(TOKEN, user?.data?.verifyOtp?.accessToken);
          Loading.dots();
          setTimeout(() => {
            Loading.remove();
            window.location.href = ` ${HOME_PAGE}`;
          }, 2000);
        } else {
          messageWarning("ມີຂໍ້ຜີດພາດເກີດຂື້ນ");
        }
      } catch (error) {
        console.log(error);
        messageError("ມີຂໍ້ຜິດພາດກະລຸນາລອງໃໝ່");
      }
    },
  });
  useEffect(() => {
    if (String(values.otp).length > 5) {
      submitForm();
    }
  }, [values.otp]);
  return (
    <div id="root" className="root front-container bg-dark vh-100 ">
      <div className="row col-md-12 p-3">
        <div className="card col-md-6  m-auto mt-5 " style={{ marginTop: "100%" }}>
          <div className="card-body">
            <center>
              <img
                src={ImgOTp}
                alt="logo"
                className="logo"
                style={{
                  marginTop: 10,
                  width: 120,
                  height: 120,
                  borderRadius: "50%",
                  border: "2px solid #400a36",
                }}
              />
            </center>
            <div className="text-center">
               <hr />
              <h3 className="text-center">ປ້ອນລະຫັດ OTP</h3>
            </div>
            <div className="mb-3 mt-2">
              <input
                type="number"
                className="form-control form-control-lg text-center fs-3 fontF"
                placeholder="000000"
                onWheel={(e) => e.target.blur()}
                name="otp"
                style={{ letterSpacing: "30px", fontSize: "20px", color: "black" }}
                value={values.otp}
                onChange={(e) => {
                  const value = e.target.value;
                  if (!value) {
                    return handleChange(e);
                  }
                  if (parseInt(value) > 0 && parseInt(value) <= 999999 && String(value)?.length <= 7) {
                    handleChange(e);
                  }
                }}
              />
              <span className="text-danger">{errors.otp}</span>
            </div>
            <hr />
            <b className="text-primary text-start text-danger " style={{ display: "flex", justifyContent: "space-between" }}>
              OTP ຈະໝົດອາຍຸໃນ 2 ນາທີ.
              <a className=" fs-6 text-success text-end " onClick={() => _clearLocal()}>
                <u>ຂໍ OTP ໃໝ່</u>
              </a>
            </b>
          </div>
        </div>
      </div>
    </div>
  );
}
