import { gql } from "@apollo/client";

export default class Schema {
  static QUERY_VACATION = gql`
    query Query(
      $where: VacationWhereInput
      $orderBy: VacationOrderBy
      $skip: Int
      $limit: Int
    ) {
      vacation(where: $where, orderBy: $orderBy, skip: $skip, limit: $limit) {
        total
        data {
          _id
          vacationId {
            _id
            title
            totalVacation
          }
          isDay
          detail
          approveBy {
            _id
            firstName
            lastName
          }
          createDate
          isApproved
          approveDate
          department {
            _id
            title_lao
            title_eng
          }
          createBy {
            _id
            firstName
            lastName
            workStartDate
          }
          startDate
          image
          endDate
          remark
        }
      }
    }
  `;

  static QUERY_VACATION_DAY = gql`
    query Query(
      $where: VacationDayWhereInput
      $orderBy: VacationDayOrderBy
      $skip: Int
      $limit: Int
    ) {
      vacationDay(
        where: $where
        orderBy: $orderBy
        skip: $skip
        limit: $limit
      ) {
        total
        data {
          _id
          title
          totalVacation
          type
        }
      }
    }
  `;

  static CREATE_VACATION = gql`
    mutation Mutation($data: VacationInput!) {
      createVacation(data: $data) {
        _id
      }
    }
  `;

  static UPDATE_VACATION = gql`
    mutation Mutation($data: VacationInput!, $where: WhereById!) {
      updateVacation(data: $data, where: $where) {
        _id
      }
    }
  `;

  static DELETE_VACATION = gql`
    mutation Mutation($where: WhereById!) {
      deleteVacation(where: $where) {
        _id
      }
    }
  `;

  static USER = gql`
    query Users($where: UserWhereInput, $limit: Int, $skip: Int) {
      users(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          _id
          firstName
          lastName
          department {
            _id
            title_lao
          }
          position {
            _id
            title_lao
          }
          branch{
            _id
          }
          center{
            st_id
          }
          status
          gender
          typePosition
        }
      }
    }
  `;

  static CREATED_VALUATION = gql`
    mutation CreateEvaluation($data: EvaluationInput!) {
      createEvaluation(data: $data) {
        status
      }
    }
  `;

  static EVALUATION_TITLE = gql`
    query EvaluationTitle(
      $where: EvaluationTitleWhereInput
      $limit: Int
      $skip: Int
    ) {
      evaluationTitle(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          _id
          title
          created_by
          created_date
        }
      }
    }
  `;

  static EVALUATION = gql`
    query Evaluation($where: EvaluationWhereInput, $limit: Int, $skip: Int) {
      evaluation(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          _id
          user_id {
            _id
            firstName
            lastName
          }
          created_by {
            _id
          }
          branch_id {
            _id
            title
          }
          center_id {
            st_id
            centerName
          }
        }
      }
    }
  `;

  static BRANCH = gql`
    query Branches($where: BranchWhereInput, $limit: Int, $skip: Int) {
      branches(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          _id
          title
          isDeleted
        }
      }
    }
  `;

  static CENTER = gql`
    query Centers($where: CenterWhereInput) {
      centers(where: $where) {
        total
        data {
          st_id
          centerName
          isPublic
          isPublic
          isMain
          position
        }
      }
    }
  `;

  static DEPARTMENT = gql`
    query Department($where: DepartmentWhereInput, $limit: Int, $skip: Int) {
      department(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          _id
          title_lao
        }
      }
    }
  `;

  static QUERY_EVALUATION = gql`
  query Evaluation($where: EvaluationWhereInput, $limit: Int, $skip: Int) {
    evaluation(where: $where, limit: $limit, skip: $skip) {
      total
      data {
        _id
        user_id {
          _id
          cvID
          firstName
          lastName
        }
        point
        branch_id {
          _id
          title
        }
        evaluation_id {
          _id
          title
        }
        center_id {
          st_id
          centerName
        }
        type
        created_by {
          _id
          firstName
          lastName
        }
      }
    }
  }
`;

static QUERY_EVALUATION_ALL = gql`
  query EvaluationAll($where: EvaluationWhereInput, $limit: Int, $skip: Int) {
    evaluationAll(where: $where, limit: $limit, skip: $skip) {
      centerEvaluation {
        center_id
        point
      }
      userEvaluation {
        user_id
        point
      }
      branchEvaluation {
        branch_id
        point
      }
    }
  }
`;
}
