import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import Select from "react-select";
import Schema from "../../pages/menu/Apollo";

export default function SelectUser({
  className,
  disabled,
  onChange,
  value,
  typeCheck,
}) {
  const [totalItem, setTotalItem] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [FetchData, { data, loading }] = useLazyQuery(Schema.USER, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    FetchData({
      variables: {
        where: {
          typePosition: typeCheck,
          status: "ACTIVE",
        },
      },
    });
  }, [typeCheck]);

  useEffect(() => {
    const results = data?.users?.data || [];
    if (results?.length > 0) {
      const _results = results.map((item) => {
        const object = {
          ...item,
          value: item?._id,
          label: `${item?.firstName} ${item?.lastName}`,
        };
        return object;
      });
      setTotalItem(_results);
    } else {
      setTotalItem([]);
    }
  }, [data]);

  useEffect(() => {
    if (value) {
      const result = totalItem?.filter((item) => item?._id === value);
      setSelectedOption(result[0] || null);
    } else {
      setSelectedOption(null);
    }
  }, [totalItem, value]);

  return (
    <div style={{ minWidth: 200 }}>
      <div className="text-center mb-1 mt-1">
        <h4>ຄົ້ນຫາຕາມຊື່</h4>
      </div>
      <Select
        className={className}
        isDisabled={typeCheck === "BRANCH" || typeCheck === "CENTER" ? true : false}
        value={selectedOption}
        placeholder={loading ? "ກຳລັງໂຫຼລດ..." : "ເລືອກພະນັກງານ"}
        onChange={(res) => {
          setSelectedOption(res);
          if (onChange) {
            onChange(res);
          }
        }}
        options={[{ value: 0, label: "ເລືອກພະນັກງານ" }, ...totalItem]}
      />
    </div>
  );
}
