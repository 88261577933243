import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import useReactRouter from "use-react-router";
import { QUERY_PAYROLL_SUMMARY, UPDATE } from "./apollo";
import {
  currency,
  getStaffLogin,
  getYearCustom,
  loadingData,
  _month,
  notiflixConfirm,
  messageSuccess,
  messageWarning,
} from "../../helper";
import BottomNav from "../../layouts/BottomNav";
import { DETAIL_MONEY } from "../../routes/app";
import NoData from "../../helper/components/NoData";
import moment from "moment";
import { Button } from "react-bootstrap";
export default function History() {
  const { history } = useReactRouter();
  const userState = getStaffLogin();
  const [reloadData, setReloadData] = useState(false);
  const [getYearLook] = useState(getYearCustom());
  const currentMonth = moment(new Date()).format("MM");
  const currentYear = moment(new Date()).format("YYYY");
  const [getYear, setGetYear] = useState(currentYear);
  const [getMoth, setGetMonth] = useState(currentMonth);
  const [_pushMoth, setMoth] = useState();
  const [upDateConfirm] = useMutation(UPDATE);
  const [_pushYear, setYear] = useState();
  // call Data
  const [fetchAnsItem, { data: dataPayrollSummary, loading }] = useLazyQuery(
    QUERY_PAYROLL_SUMMARY,
    {
      fetchPolicy: "cache-and-network",
    }
  );
  useEffect(() => {
    fetchAnsItem({
      variables: {
        where: {
          empID: parseInt(userState?._id),
          forMonth: parseInt(_pushMoth) ? parseInt(_pushMoth) : undefined,
          forYear: parseInt(_pushYear) ? parseInt(_pushYear) : undefined,
        },
        orderBy: "DESC",
      },
    });
  }, [reloadData, _pushMoth, _pushYear]);

  function _pushMothAndYear() {
    setYear(getYear);
    setMoth(getMoth);
  }

  const _conFirm = async (_id) => {
    notiflixConfirm("ຕ້ອງການຢືນຢັນຖືກຕ້ອງແທ້ ຫຼື ບໍ່?", async () => {
      try {
        const { data: updateData } = await upDateConfirm({
          variables: {
            data: {
              confirmStatus: "CONFIRMED",
            },
            where: {
              _id: _id,
            },
          },
        });
        if (updateData) {
          messageSuccess("ຢືນຢັນເງິນເດືອນສຳເລັດ");
          setReloadData(!reloadData);
        }
      } catch (error) {
        messageWarning("ຢືນຢັນບໍ່ສຳເລັດ");
        console.log(error);
      }
    });
  };

  return (
    <div style={{ marginTop: -15 }}>
      <div id="appCapsule">
        <div className="justify-content-md-center">
          <div className="appHeader text-light border-0">
            <div style={{ flex: 1 }} className="text-center"></div>
            ການເຄື່ອນໄຫວ
            <div
              className="text-white pageTitle text-right text-nowrap pr-0"
              style={{ flex: 1 }}
            >
              <button
                className="btn text-white mr-0"
                onClick={() => setReloadData(!reloadData)}
              >
                {loading ? loadingData(23) : <i className="icon-cycle fs-4" />}
              </button>
            </div>
          </div>
          <div className="section mt-4" style={{ marginTop: "200px" }}>
            <div className="input-group">
              <select
                className="form-select bg-white text-center"
                onChange={(e) => setGetMonth(e.target.value)}
              >
                {_month?.map((item, index) => (
                  <option
                    key={index}
                    selected={item.id === currentMonth ? true : false}
                    value={item?.id}
                  >
                    {item?.month}
                  </option>
                ))}
              </select>
              <select
                className="form-select bg-white text-center"
                onChange={(e) => setGetYear(e.target.value)}
              >
                {getYearLook?.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
              <button
                type="button"
                className="btn btn-lg btn-primary"
                onClick={() => _pushMothAndYear()}
              >
                <i className="icon-search1 me-1" /> ຄົ້ນຫາ
              </button>
            </div>
          </div>
          <div className="section  mb-2 mt-5">
            <div className="transactions">
              {dataPayrollSummary?.summaryPayroll?.total > 0 ? (
                <div className="listView">
                  {dataPayrollSummary &&
                    dataPayrollSummary?.summaryPayroll?.data?.map(
                      (data, index) => (
                        <>
                          <a
                            key={index}
                            className="item pr-0 "
                            style={{ cursor: "pointer" }}
                          >
                            <div
                              className="detail col-md-10"
                              onClick={() =>
                                // btoa(`${getID?._id}`)
                                history.push(`${DETAIL_MONEY}/${btoa(data?._id)}`)
                              }
                            >
                              <i className="fa-solid fa-hand-holding-dollar fa-2x" />
                              <div className="ml-2">
                                <strong>
                                  ເດືອນ/ປີ:{" "}
                                  {data?.forMonth ? data?.forMonth : "-"} /{" "}
                                  {data?.forYear ? data?.forYear : "-"}
                                </strong>
                                <b className="text-black">
                                  ລວມເງິນ:{" "}
                                  {data?.finalIncome
                                    ? currency(data?.finalIncome)
                                    : 0}{" "}
                                </b>
                                {data?.isDrop === 1 ? (
                                  <p className="text-danger">
                                    ເງິນເດືອນຂອງທ່ານຖືກກັກ
                                  </p>
                                ) : null}
                              </div>
                            </div>
                            <div className="right">
                              {data?.confirmStatus === "UNCONFIRMED" ? (
                                <>
                                  <Button
                                    type="button"
                                    className="btn btn-success"
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      _conFirm(data?._id);
                                    }}
                                  >
                                    <i className="fa-solid fa-circle-check me-2 fs-3 " />
                                    ຢືນຢັນ
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <center
                                    className="text-center text-success text-nowrap"
                                    style={{ fontSize: "16px" }}
                                  >
                                    ທ່ານໄດ້ຢືນຢັນແລ້ວ
                                  </center>
                                </>
                              )}
                            </div>
                          </a>
                        </>
                      )
                    )}
                </div>
              ) : (
                <NoData loading={loading} />
              )}
            </div>
          </div>
          <BottomNav />
        </div>
      </div>
    </div>
  );
}
