import { gql } from "@apollo/client";
export const LOGIN_GET_OTP = gql`
  mutation StaffLogin($data: UserLoginInput!) {
    staffLogin(data: $data) {
      otpToken
    }
  }
`;

export const EMPLOYEE_OTP = gql`
  mutation VerifyOtp($data: VerifyOtpInput!) {
    verifyOtp(data: $data) {
      accessToken
    }
  }
`;
