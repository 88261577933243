import React, { useState } from "react";
import { Formik } from "formik";
import { useMutation } from "@apollo/client";
import { LOGIN_GET_OTP } from "./gql";
import { Loading } from "notiflix";
import { messageWarning, TOKEN_OTP } from "../../helper";
import Imglogo from "../../img/logIn.jpeg";
import { FormControl, InputAdornment, OutlinedInput } from "@mui/material";
import { VERIFY_OPT } from "../../routes/app";
export default function Login() {
  const [showPassword, setShowPassword] = useState("password");
  window.history.forward();
  const [employeeLogin] = useMutation(LOGIN_GET_OTP);
  return (
    <>
      <Formik
        initialValues={{
          phoneNumber: "",
          password: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.phoneNumber) errors.phoneNumber = "ກະລຸນາປ້ອນເບີໂທ";
          else if (values.phoneNumber.length > 8) {
            errors.phoneNumber = "ເບີໂທຕ້ອງມີບໍ່ເກີນ 8 ໂຕເລກ";
          } else if (values.phoneNumber.length < 7) {
            errors.phoneNumber = "ເບີໂທຕ້ອງມີ 7 ໂຕເລກຂື້ນໄປ";
          }
          if (!values.password) errors.password = "ກະລຸນາປ້ອນລະຫັດຜ່ານ";
          return errors;
        }}
        onSubmit={async (values) => {
          try {
            let user = await employeeLogin({
              variables: {
                data: {
                  phoneNumber: parseInt(values?.phoneNumber),
                  password: String(values?.password),
                },
              },
            });
            if (user) {
              localStorage.setItem(TOKEN_OTP, user?.data?.staffLogin?.otpToken);
              Loading.dots();
              setTimeout(() => {
                Loading.remove();
                window.location.href = ` ${VERIFY_OPT}`;
              }, 2000);
            } else {
              messageWarning("ເບີໂທຫຼືລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ");
            }
          } catch (error) {
            messageWarning("ເບີໂທຫຼືລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ");
            console.log(error);
          }
        }}
      >
        {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
          <>
            <div className="header-bg header-bg-1 text-white" />
            <div className="body-content bg-white">
              <center>
                <div
                  style={{
                    marginTop: -70,
                    width: 120,
                    height: 120,
                    borderRadius: "50%",
                    border: "2px solid #400a36",
                    padding: 5,
                    backgroundColor: "white",
                  }}
                >
                  <img
                    src={Imglogo}
                    alt="logo"
                    className="logo"
                    style={{
                      borderRadius: "50%",
                    }}
                  />
                </div>
              </center>
              <div className="container">
                <center>
                  <h2>ເຂົ້າສູ່ລະບົບ</h2>
                </center>
                <div className="authentication-form">
                  <form>
                    <div className="form-group pb-15">
                      <div className="input-group">
                        <FormControl fullWidth sx={{ m: 0 }}>
                          <OutlinedInput
                            startAdornment={
                              <InputAdornment position="start">
                                <i className="icon-phone" />
                              </InputAdornment>
                            }
                            value={values.phoneNumber}
                            name="phoneNumber"
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (!value) {
                                return handleChange(e);
                              }
                              if (
                                parseInt(value) > 0 &&
                                parseInt(value) <= 99999999 &&
                                String(value)?.length <= 8
                              ) {
                                handleChange(e);
                              }
                            }}
                            placeholder="ປ້ອນເບີໂທ"
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                handleSubmit();
                              }
                            }}
                            error={errors.phoneNumber}
                          />
                        </FormControl>
                      </div>
                      <div className="text-danger ">{errors?.phoneNumber}</div>
                    </div>
                    <div className="form-group pb-15">
                      <FormControl fullWidth sx={{ m: 0 }}>
                        <OutlinedInput
                          startAdornment={
                            <InputAdornment position="start">
                              <i class="fa-solid fa-unlock-keyhole"></i>
                            </InputAdornment>
                          }
                          name="password"
                          value={values.password}
                          type={showPassword}
                          placeholder="ປ້ອນລະຫັດຜ່ານ"
                          onChange={handleChange}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              handleSubmit();
                            }
                          }}
                          error={errors.password}
                          endAdornment={
                            <InputAdornment position="start">
                              <i
                                // className="icon-eye"
                                className={
                                  showPassword === "text"
                                    ? "fa-solid fa-eye-slash fs-6"
                                    : "icon-eye fs-4"
                                }
                                onClick={() =>
                                  showPassword === "password"
                                    ? setShowPassword("text")
                                    : setShowPassword("password")
                                }
                                style={{ cursor: "pointer" }}
                              />
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                      <div className="text-danger ">{errors?.password}</div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <hr />
                      </div>
                      <div className="col-2 text-center">
                        <i className="icon-lock fs-2" />
                      </div>
                      <div className="col-5">
                        <hr />
                      </div>
                    </div>
                    <button
                      type="button"
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                      className="btn btn btn-lg btn-primary rounded full-width mb-10 "
                    >
                      ເຂົ້າສູ່ລະບົບ
                    </button>
                  </form>
                  <div className="text-center mt-5" style={{ height: "60px" }}>
                    <i className="fa-solid fa-copyright me-1" />
                    2023 development by anousith express V0.1
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Formik>
    </>
  );
}
