import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { aws_url_employee_Image, gcpUpload, messageError, messageSuccess, messageWarning, notiflixConfirm } from "../../../helper";
import CompressImage from "../../../until/UploadFIle";
import { FcAddImage } from "react-icons/fc";
import { Form } from "react-bootstrap";
import Schema from "../Apollo";
import { useMutation } from "@apollo/client";

export default function ModalUploadImage({item}) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [image, setImage] = useState("");
    const [newFilename] = useState("");
    const [reloadData, setReloadData] = useState(false);
    const [updateVacation] = useMutation(Schema.UPDATE_VACATION);

    // choose image and upload image
    const handleChangeFile = async (e) => {
        const file = e.target.files?.[0];
        if (!file) return;
        try {
            const compressedFile = await CompressImage(file);
            const reader = new FileReader();
            reader.onload = async (e) => {
                setImage(e.target?.value);
                const formData = new FormData();
                formData.append("file", compressedFile);
                const res = await gcpUpload({
                    file: compressedFile,
                    bucketName: "anousith-bucket",
                    pathname: "employesImage",
                    filename: newFilename,
                });
                if (res?.publicUrl) {
                    setImage(res?.filename);
                }
            };
            reader.readAsDataURL(compressedFile);
        } catch (error) {
            console.log(error);
            messageError("ອັບໂຫຼດບໍ່ສຳເລັດ");
        }
    };

    const handleUpdate = (_id) => {
        if(!image) return messageWarning("ກະລຸນາເລືອກຮູບກອນ");
        notiflixConfirm("ຕ້ອງການອັບຮຼບ ແທ້ ຫຼື ບໍ່?", async () => {
            try {
                const Update = await updateVacation({
                    variables: {
                        where: {
                            _id: _id,
                        },
                        data: {
                            image: String(image),
                        },
                    },
                });
                if (Update) {
                    messageSuccess("ການດຳເນີນງານສຳເລັດ");
                    setReloadData(!reloadData);
                    setShow(false)
                } else {
                    messageError("ການດຳເນີນງານບໍ່ສຳເລັດ");
                }
            } catch (error) {
                console.log(error);
                messageError("ມີຂໍ້ມູນບາງຢ່າງຜິດພາດ");
            }
        });
    };

    return (
        <div>
            <div className="d-flex justify-content-center">
                <button className="btn btn-dark" onClick={handleShow}>
                    ອັບໂຫຼດຮູບຍັອນຫຼັງ
                </button>
            </div>
            <Modal show={show} >
                <Modal.Header>
                    ກໍລະນີ້ອັບຮູບຍ້ອນຫຼັງການລາເຈັບປ່ວຍ
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className='mt-2'>
                        <div className="d-flex align-items-center justify-content-center">
                            <input
                                type="file"
                                id="file-upload"
                                onChange={handleChangeFile}
                                accept="image/png, image/gif, image/jpeg"
                                hidden
                            />
                            <div>
                                {image ? (
                                    <label htmlFor="file-upload">
                                        <div
                                            style={{
                                                height: '100%',
                                                width: '100%',
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                background: '#f5f5f5',
                                                border: '1px solid #ddd',
                                                borderRadius: 7,
                                                overflow: 'hidden',
                                            }}
                                        >
                                            <img
                                                src={`${aws_url_employee_Image}/${image}`}
                                                style={{
                                                    display: 'block',
                                                    margin: '0 auto',
                                                    maxWidth: '100%',
                                                    maxHeight: '100%',
                                                }}
                                                alt="Employee Image"
                                            />
                                        </div>
                                    </label>
                                ) : (
                                    <label htmlFor="file-upload">
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <FcAddImage size={100} />
                                        </div>
                                    </label>
                                )}
                            </div>
                        </div>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={handleClose}>ຍົກເລີກ</button>
                    <button className="btn btn-success" onClick={() => handleUpdate(item?._id)}> ບັນທຶກ</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}