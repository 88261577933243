import React, { useEffect, useState, useRef } from "react";
import { Card, Row, Col, Form } from "react-bootstrap";
import { RiArrowGoBackFill } from "react-icons/ri";
import useReactRouter from "use-react-router";
import { MENU } from "../../routes/app";
import SignatureEmployee from "./Component/SignatureEmployee";
import {
  getStaffLogin,
  loadingData,
  messageError,
  messageSuccess,
  messageWarning,
  aws_url_employee_Image,
  gcpUpload,
  notiflixConfirm,
} from "../../helper";
import { useLazyQuery, useMutation } from "@apollo/client";
import Schema from "./Apollo";
import { EMPLOYEE_QUEY } from "../profile/apollo";
import _ from "lodash";
import CompressImage from "../../until/UploadFIle";
import { FcAddImage } from "react-icons/fc";

export default function Absence() {
  const { history } = useReactRouter();
  const user = getStaffLogin();
  const department = user?.department?._id;
  const fullName =
    user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : "";
  const [typeVacation, setTypeVacation] = useState("");
  const type = typeVacation?.type;
  const idVacationDay = typeVacation?._id;
  const [totalDay, setTotalDay] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [detail, setDetail] = useState("");
  const [data, setData] = useState([]);
  const [reloadData, setReloadData] = useState(false);
  const [newFilename] = useState("");
  const [image, setImage] = useState("");
  const [File, setFile] = useState("");
  const [imageURL, setImageURL] = useState(null);
  const [vacationData, setVacationData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [createVacation] = useMutation(Schema.CREATE_VACATION);
  const [queryVacationDay, { data: result, loading }] = useLazyQuery(
    Schema.QUERY_VACATION_DAY,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(() => {
    queryVacationDay({
      variables: {
        where: {},
      },
    });
  }, [reloadData]);

  useEffect(() => {
    setData(result?.vacationDay?.data);
  }, [result]);

  const [queryUser, { data: User }] = useLazyQuery(EMPLOYEE_QUEY, {
    fetchPolicy: "cache-and-network",
  });
  useEffect(() => {
    queryUser({
      variables: {
        where: {
          _id: parseInt(user._id),
        },
      },
    });
  }, [reloadData]);

  useEffect(() => {
    setUserData(User?.users?.data[0] || []);
  }, [User]);

  const [queryVacation, { data: result1 }] = useLazyQuery(
    Schema.QUERY_VACATION,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(() => {
    queryVacation({
      variables: {
        where: {
          createBy: Number(user._id),
          vacationId: parseInt(typeVacation._id),
          isApproved: "COMPLETE",
        },
      },
    });
  }, [queryVacation, typeVacation, reloadData]);

  useEffect(() => {
    setVacationData(result1?.vacation?.data);
  }, [result1]);

  const handleCreate = async (data) => {
    if (totalDay > totalVacation) {
      return messageWarning("ຈຳນວນມືລາພັກໝົດເເລ້ວ");
    }
    if (!typeVacation) return messageWarning("ກະລຸນາເລືອກປະເພດການລາພັກ");
    if (!totalDay) return messageWarning("ກະລຸນາເລືອກຈຳນວນວັນ");
    if (!startDate) return messageWarning("ກະລຸນາເລືອກວັນເວລາເລິ່ມພັກ");
    if (!endDate) return messageWarning("ກະລຸນາເລືອກວັນເວລາສິນສຸດ");
    if (!detail) return messageWarning("ກະລຸນາປ້ອນລາຍລະອຽດ");
    if (!File) return messageWarning("ກະລຸນາເຊັນລາຍເຊັນກອນ");

    notiflixConfirm("ຕ້ອງການບັນທຶກ ແທ້ ຫຼື ບໍ່?", async () => {
      try {
        const Create = await createVacation({
          variables: {
            data: {
              vacationId: Number(typeVacation?._id),
              isDay: Number(totalDay),
              startDate: startDate,
              endDate: endDate,
              detail: detail,
              department: department,
              signatureCreate: String(File),
              image: String(image),
            },
          },
        });
        if (Create) {
          messageSuccess("ການດຳເນີນງານສຳເລັດ");
          setTotalDay(0);
          setStartDate("");
          setEndDate("");
          setDetail("");
          setTypeVacation("");
          setFile("");
          setReloadData(!reloadData);
          history.push(`${MENU}`);
        } else {
          messageError("ການດຳເນີນງານບໍ່ສຳເລັດ");
        }
      } catch (error) {
        console.log(error);
        messageError("ມີຂໍ້ມູນບາງຢ່າງຜິດພາດ");
      }
    });
  };

  //ຄຳນວນວາຖ້າອາຍຸການເຮັດ13ເດືອນຈືງໃຫ້ເຫັນປູ່ມ 4ມື້-5ມື້
  const [isWorkStartDatePassed, setIsWorkStartDatePassed] = useState(false);
  useEffect(() => {
    if (userData.workStartDate) {
      const currentDate = new Date();
      const workStartDate = new Date(userData.workStartDate);
      const isPassed13Months =
        currentDate >
        new Date(workStartDate.setMonth(workStartDate.getMonth() + 13));
      setIsWorkStartDatePassed(isPassed13Months);
    }
  }, [userData]);

  // ປຽບທຽບການລາພັກຂອງພະນັກງານ
  const totalUse = _.sumBy(vacationData, "isDay");
  const typeTotal = typeVacation?.totalVacation || 0;
  const totalVacation = typeTotal - totalUse;

  // choose image and upload image
  const handleChangeFile = async (e) => {
    const file = e.target.files?.[0];
    if (!file) return;
    try {
      const compressedFile = await CompressImage(file);
      const reader = new FileReader();
      reader.onload = async (e) => {
        setImage(e.target?.value);
        const formData = new FormData();
        formData.append("file", compressedFile);
        const res = await gcpUpload({
          file: compressedFile,
          bucketName: "anousith-bucket",
          pathname: "employesImage",
          filename: newFilename,
        });
        if (res?.publicUrl) {
          setImage(res?.filename);
        }
      };
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.log(error);
      messageError("ອັບໂຫຼດບໍ່ສຳເລັດ");
    }
  };

  return (
    <div>
      <div className="justify-content-md-center">
        <div className="appHeader text-light border-0">
          <div className="col-6">
            <a
              onClick={() => history.push(`${MENU}`)}
              style={{ cursor: "pointer" }}
            >
              <RiArrowGoBackFill size={20} />
            </a>
          </div>
          <div
            className="col-6 text-white pageTitle text-right text-nowrap pr-0"
            style={{ flex: 1 }}
          >
            <button
              className="btn text-white mr-0"
              onClick={() => setReloadData(!reloadData)}
            >
              {loading ? loadingData(23) : <i className="icon-cycle fs-4" />}
            </button>
          </div>
        </div>
        <div className="container">
          <Card
            className="p-2"
            style={{ marginTop: "70px", backgroundColor: "#F2F3F3" }}
          >
            <Form onSubmit={(e) => e.preventDefault()}>
              <div className="text-center">
                <h2>ເເບບຟອມຂໍລາພັກ</h2>
              </div>
              <Form.Group>
                <Form.Label className="d-flex align-items-center">
                  <span className="me-2">ຊື່ ແລະ ນາມສະກຸນ</span>
                </Form.Label>
                <div className="ms-2">
                  <h2>{fullName}</h2>
                </div>
              </Form.Group>
              <Form.Group>
                <Row>
                  <Col className="col-6">
                    <Form.Label className="d-flex align-items-center mt-1">
                      <span className="me-2">ວັນທີເລິ່ມພັກ</span>
                    </Form.Label>
                    <input
                      type="date"
                      className="form-control-sm"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </Col>
                  <Col className="col-6">
                    <Form.Label className="d-flex align-items-center mt-1">
                      <span className="me-2">ວັນທີສິ້ນສຸດ</span>
                    </Form.Label>
                    <input
                      type="date"
                      className="form-control-sm"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mt-2">
                <Form.Label className="d-flex align-items-center mt-1">
                  {" "}
                  ເລືອກປະເພດການລາພັກ
                </Form.Label>
                <Row>
                  <div>
                    {data?.slice(0, 3).map((item) => (
                      <div key={item._id}>
                        <Form.Check
                          type="radio"
                          name="radioGroup"
                          id="radio"
                          value={typeVacation}
                          label={item.title}
                          onChange={() => setTypeVacation(item)}
                        />
                      </div>
                    ))}
                  </div>
                  {user?.gender === "FEMALE" && (
                    <div>
                      {data?.slice(3, 4).map((item) => (
                        <div key={item._id}>
                          <Form.Check
                            type="radio"
                            name="radioGroup"
                            id="radio"
                            value={typeVacation}
                            label={item.title}
                            onChange={() => setTypeVacation(item)}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </Row>
              </Form.Group>
              <Form.Group>
                <Form.Label className="d-flex align-items-center mt-1">
                  <span className="me-2">ຈຳນວນວັນ</span>
                </Form.Label>
                <Row>
                  <Col className="col-6">
                    <div>
                      <Form.Check
                        type="radio"
                        name="radioGroup1"
                        id="radio1"
                        label="ລາພັກເຄິ່ງວັນ."
                        value={totalDay}
                        onChange={() => setTotalDay(0.5)}
                      />
                    </div>
                    <div>
                      <Form.Check
                        type="radio"
                        name="radioGroup1"
                        id="radio1"
                        label="1 ວັນ."
                        value={totalDay}
                        onChange={() => setTotalDay(1.0)}
                      />
                    </div>
                    <div>
                      <Form.Check
                        type="radio"
                        name="radioGroup1"
                        id="radio1"
                        label="1 ວັນກັບເຄີ່ງມື້."
                        value={totalDay}
                        onChange={() => setTotalDay(1.5)}
                      />
                    </div>
                    <div>
                      <Form.Check
                        type="radio"
                        name="radioGroup1"
                        id="radio1"
                        label="2 ວັນ."
                        value={totalDay}
                        onChange={() => setTotalDay(2.0)}
                      />
                    </div>
                    <div>
                      <Form.Check
                        type="radio"
                        name="radioGroup1"
                        id="radio1"
                        label="3 ວັນ."
                        value={totalDay}
                        onChange={() => setTotalDay(3.0)}
                      />
                    </div>
                    {isWorkStartDatePassed === true && (
                      <div>
                        <Form.Check
                          type="radio"
                          name="radioGroup1"
                          id="radio1"
                          label="4 ວັນ."
                          value={totalDay}
                          onChange={() => setTotalDay(4.0)}
                        />
                      </div>
                    )}
                  </Col>
                  <Col className="col-6">
                    {isWorkStartDatePassed === true && (
                      <>
                        <div>
                          <Form.Check
                            type="radio"
                            name="radioGroup1"
                            id="radio1"
                            label="5 ວັນ."
                            value={totalDay}
                            onChange={() => setTotalDay(5.0)}
                          />
                        </div>
                      </>
                    )}
                    {type === "GIVING_BIRTH" && (
                      <div>
                        <Form.Check
                          type="radio"
                          name="radioGroup1"
                          id="radio1"
                          label="ເກິດລູກ 105 ວັນຕໍ່ຄັ້ງ."
                          value={totalDay}
                          onChange={() => setTotalDay(105.0)}
                        />
                      </div>
                    )}
                    {type === "SICK_DAY" && (
                      <>
                        <div>
                          <Form.Check
                            type="radio"
                            name="radioGroup1"
                            id="radio1"
                            label="5 ວັນກັບເຄີ່ງມື້."
                            value={totalDay}
                            onChange={() => setTotalDay(5.5)}
                          />
                        </div>
                        <div>
                          <Form.Check
                            type="radio"
                            name="radioGroup1"
                            id="radio1"
                            label="15 ວັນ."
                            value={totalDay}
                            onChange={() => setTotalDay(15.0)}
                          />
                        </div>
                        <div>
                          <Form.Check
                            type="radio"
                            name="radioGroup1"
                            id="radio1"
                            label="30 ວັນ."
                            value={totalDay}
                            onChange={() => setTotalDay(30.0)}
                          />
                        </div>
                      </>
                    )}
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group>
                <Form.Label className="d-flex align-items-center mt-1">
                  <span className="me-2">ລາຍລະອຽດ</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  style={{ height: "200px" }}
                  value={detail}
                  onChange={(e) => setDetail(e.target.value)}
                />
              </Form.Group>
              {idVacationDay === 3 && (
                <Form.Group className="mt-2">
                  <Form.Label>
                    <span className="me-2">ເລືອກຮູບ</span>
                  </Form.Label>
                  <div className="d-flex align-items-center justify-content-center">
                    <input
                      type="file"
                      id="file-upload"
                      onChange={handleChangeFile}
                      accept="image/png, image/gif, image/jpeg"
                      hidden
                    />
                    <div>
                      {image ? (
                        <label htmlFor="file-upload">
                          <div
                            style={{
                              height: "100%",
                              width: "100%",
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              background: "#f5f5f5",
                              border: "1px solid #ddd",
                              borderRadius: 7,
                              overflow: "hidden",
                            }}
                          >
                            <img
                              src={`${aws_url_employee_Image}/${image}`}
                              style={{
                                display: "block",
                                margin: "0 auto",
                                maxWidth: "100%",
                                maxHeight: "100%",
                              }}
                              alt="Employee Image"
                            />
                          </div>
                        </label>
                      ) : (
                        <label htmlFor="file-upload">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <FcAddImage size={100} />
                          </div>
                        </label>
                      )}
                    </div>
                  </div>
                </Form.Group>
              )}
              <div className="mt-2">
                <div className="mb-2">ລາຍເຊັນຜູ້ສະເໜີ</div>
                {File ? (
                  <>
                    <img
                      src={`${aws_url_employee_Image}/${File}`}
                      style={{
                        display: "block",
                        margin: "0 auto",
                        width: "70%",
                        height: "70%",
                      }}
                      alt="Employee Image"
                    />
                  </>
                ) : (
                  <>
                    <SignatureEmployee
                      setImageURL={setImageURL}
                      setFile={setFile}
                    />
                  </>
                )}
              </div>
              <div className="text-right">
                <hr />
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => handleCreate()}
                >
                  ຢືນຍັນ
                </button>
              </div>
            </Form>
          </Card>
          <Card className="p-2 mt-2" style={{ backgroundColor: "#F2F3F3" }}>
            <div>
              <h3>
                <span className="text-danger">ໝາຍເຫດ:</span>
              </h3>
              <h5>
                1. ພະນັກງານຕ້ອງສະເໜີໃບລາພັກເພື່ອຂໍອະນຸມັດ ຈາກຫົວໜ້າສາຍງານຂອງຕົນ
                ໃຫ້ຖືກຕ້ອງຕາມລະບຽບ.
              </h5>
              <h5>
                2. ການລາພັກປະຈຳປີ ສຳລັບພະນັກງານທີ່ມີອາຍຸການ 1ປີລົງມາ
                ເເມ່ນລາພັກໄດ້ສູງສຸດບໍ່ໃຫ້ເກີນ 3 ວັນຕໍ່ຄັ້ງ,
                ສຳລັບພະນັກງານທີ່ມີອາຍຸການ 1ປິຂຶ້ນໄປ
                ເເມ່ນລາພັກໄດ້ສູງສຸດບໍ່ໃຫ້ເກີນ 5 ວັນຕໍ່ຄັ້ງ.
              </h5>
              <h5>
                3. ການລາພັກກິດທຸລະສ່ວນຕົວ,
                ພະນັກງານຕ້ອງສະເໜີໃບລາພັກເພື່ອຂໍອະນຸມັດ ຈາກຫົວໜ້າສາຍງານຂອງຕົນ
                ໃຫ້ຖືກຕ້ອງຕາມລະບຽບ ເເລະ
                ຕ້ອງມີເອກະສານຕີດຄັດມາທຸກຄັ້ງທີ່ມີການລາພັກ.
              </h5>
              <h5>
                4. ການລາພັກເຈັບປ່ວຍ, ພະນັກງານຕ້ອງສະເໜີໃບລາພັກເພື່ອຂໍອະນຸມັດ
                ຈາກຫົວໜ້າສາຍງານຂອງຕົນ ໃຫ້ຖືກຕ້ອງຕາມລະບຽບ ເເລະ
                ຕ້ອງມີເອກະສານໃບຢັ້ງຢືນຈາກໂຮງໝໍມາຕິດຄັດ.
              </h5>
              <h5>
                5. ການລາພັກເກີດລູກ (ສຳລັບພະນັກງານຍິງ),
                ພະນັກງານຕ້ອງຢື່ນໃບລາພັກເພື່ອຂໍອະນຸມັດ ຈາກຫົວໜ້າສາຍງານຂອງຕົນ
                ໃຫ້ຖືກຕ້ອງຕາມລະບຽບ ເເລະ ຕ້ອງມີເອກະສານຕີດຄັດມາພ້ອມ.
              </h5>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}
